import React, { useState } from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';
import { Icon } from './ui/Icon';
import DeleteConfirmModal from './DeleteConfirmModal';
import './settingsItem.css';

type Props = {
  itemId: string;
  heading: string;
  subHeading?: string;
  deleteConfirmText: string;
  deleteItem: Function;
  setCurrentEditModal?: Function;
};

const SettingsItem: React.FC<Props> = ({
  itemId,
  heading,
  subHeading,
  deleteConfirmText,
  deleteItem,
  setCurrentEditModal,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  return (
    <ListGroup.Item>
      <Row>
        <Col sm={10} lg={10} className="text-left">
          <Row>
            <Col className="header">{heading}</Col>
          </Row>
          {subHeading ? (
            <Row>
              <Col>{subHeading}</Col>
            </Row>
          ) : null}
        </Col>

        <Col className="my-auto text-right" sm={1} lg={1}>
          {setCurrentEditModal && <Icon icon="pencil" onClick={() => setCurrentEditModal(itemId)} />}
        </Col>

        <Col className="my-auto text-right" sm={1} lg={1}>
          <Icon icon="trash" onClick={() => setShowDeleteModal(true)} />
        </Col>
      </Row>

      <DeleteConfirmModal
        showModal={showDeleteModal}
        text={deleteConfirmText}
        deleteFunction={() => deleteItem(itemId)}
        setShowModal={setShowDeleteModal}
      />
    </ListGroup.Item>
  );
};

export default SettingsItem;
