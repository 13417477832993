import React from 'react';
import { Button as BootstrapButton, ButtonProps, Spinner } from 'react-bootstrap';
import './button.css';

export type SpinnerButtonProps = ButtonProps & {
  spinner?: boolean;
};

export const Button: React.FC<SpinnerButtonProps> = ({ children, variant, spinner, ...props }) => {
  return (
    <>
      {/* TODO: Make this less stupid */}
      <style type="text/css">
        {`
          .btn-custom-primary {
            background: #ff15b0;
            border-radius: 100px;
            font-weight: bold;
            /* height: 30px; */
            font-size: 12px;
            border: 1px solid #ff15b0;
            color: white;
            padding-left: 30px;
            padding-right: 30px;
            white-space: nowrap;
          }
          .btn-custom-primary:hover {
            color: white;
            background: #e10095;
          }
          .btn-custom-secondary {
            background: white;
            border-radius: 100px;
            font-weight: bold;
            /* height: 30px; */
            font-size: 12px;
            border: 1px solid #ff15b0;
            color: #ff15b0;
            padding-left: 30px;
            padding-right: 30px;
            white-space: nowrap;
          }
          .btn-custom-secondary:hover {
            background: #fff2fb;
            color: #ff15b0;
          }
        `}
      </style>
      <BootstrapButton variant={variant ? variant : 'custom-primary'} disabled={spinner || props.disabled} {...props}>
        {spinner ? <Spinner animation="border" variant="light" size="sm" /> : children}
      </BootstrapButton>
    </>
  );
};
