import { Formik } from 'formik';
import React, { useState, useCallback, useEffect } from 'react';
import { Form, Row, Col, ListGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuth } from './AuthProvider';
import { handleError } from '../helper/error';
import { Button } from './ui/Button';
import LoginFlowCard from './LoginFlowCard';
import { FormTextInput } from './ui/FormTextInput';
import { Hospital } from './Hospitals';
import { AxiosResponse } from 'axios';

const OnboardingHospitals: React.FC = () => {
  const [hospitals, setHospitals] = useState<Hospital[]>([]);
  const { Get, Post } = useAuth();
  const [err, setError] = useState<string | undefined>(undefined);
  const history = useHistory();

  const refreshHospitals = useCallback(async () => {
    try {
      const res: AxiosResponse<Hospital[]> = await Get(`/api/v1/hospitals`);
      setHospitals(res.data);
    } catch (err) {
      handleError(err, setError);
    }
  }, [Get]);

  useEffect(() => {
    refreshHospitals();
  }, [refreshHospitals]);

  // Schema for yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Name must be at least two characters').required('Name is required'),
  });

  return (
    <LoginFlowCard title="Add hospitals" error={err} setError={setError}>
      <Row>
        <Col className="text-left mb-3">
          Finally, we'll add some hospitals. Add hospitals where you see patients. This will allow you to create
          patients associated with these hospitals. You can always add or remove them later.
        </Col>
      </Row>

      <Formik
        validationSchema={validationSchema}
        initialValues={{ name: '' }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);

          try {
            await Post(`/api/v1/hospitals`, { name: values.name });
            refreshHospitals();

            // Formik functions.
            resetForm();
            setSubmitting(false);
          } catch (err) {
            setSubmitting(false);
            handleError(err, setError);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormTextInput
              name="name"
              header="Name"
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            />

            <Row className="mb-2">
              <Col>
                <Button className="mx-1" type="submit" spinner={isSubmitting}>
                  {hospitals.length > 0 ? 'Save another hospital' : 'Save hospital'}
                </Button>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button className="mx-1" variant="custom-secondary" onClick={() => history.push('/onboarding/users')}>
                  Previous step
                </Button>

                <Button className="mx-1" variant="custom-secondary" onClick={() => history.push('/notes')}>
                  {hospitals.length > 0 ? 'Finish setup' : 'Skip'}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      {hospitals.length > 0 ? (
        <ListGroup className="mt-4">
          <Row>
            <Col className="text-left h5">Hospitals</Col>
          </Row>
          {hospitals.map((h) => {
            return (
              <ListGroup.Item key={h.id}>
                <Row>
                  <Col className="text-left font-weight-bold">{h.name}</Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ) : null}
    </LoginFlowCard>
  );
};

export default OnboardingHospitals;
