import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import Users from './Users';
import Hospitals from './Hospitals';
import Roles from './Roles';
import Profile from './Profile';
import Billing from './Billing';
import Account from './Account';
import Referrals from './Referrals';
import { useIsMobile } from './MobileProvider';
import { useAuth } from './AuthProvider';
import './settings.css';
import BackButton from './ui/BackButton';

type UrlParams = {
  page?: string;
};

const Settings: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<string>();
  const { page } = useParams<UrlParams>();
  const history = useHistory();
  const { isMobile } = useIsMobile();
  const [settingSelected, setSettingSelected] = useState<boolean>(false); // This is used for mobile.
  const auth = useAuth();

  const profileKey = 'profile';
  const usersKey = 'users';
  const billingKey = 'billing';
  const accountKey = 'account';
  const rolesKey = 'roles';
  const hospitalsKey = 'hospitals';
  const referralsKey = 'referrals';

  useEffect(() => {
    if (page) {
      setCurrentPage(page);
      setSettingSelected(true);
    } else {
      if (!isMobile()) {
        // Default to profile page on desktop.
        setCurrentPage(profileKey);
        history.push('/settings/profile');
      } else {
        setSettingSelected(false);
      }
    }
  }, [page, history, isMobile]);

  const getSettingsPage = (key?: string) => {
    switch (key) {
      case profileKey:
        return <Profile />;
      case usersKey:
        return <Users />;
      case billingKey:
        return <Billing />;
      case accountKey:
        return <Account />;
      case rolesKey:
        return <Roles />;
      case hospitalsKey:
        return <Hospitals />;
      case referralsKey:
        return <Referrals />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm={12} md={12} lg={11}>
          <Row>
            {!isMobile() || (isMobile() && !settingSelected) ? (
              <Col sm={12} lg={2}>
                <Row className="text-left py-2">
                  <Col>
                    <BackButton to={'/notes'}>Patients</BackButton>
                  </Col>
                </Row>
                <ListGroup>
                  <ListGroup.Item
                    className={`${currentPage === profileKey && !isMobile() ? 'linkActive' : ''} text-left`}
                    action
                    as={Link}
                    to={`/settings/profile`}
                    onClick={() => {
                      setCurrentPage(profileKey);
                      setSettingSelected(true);
                    }}
                  >
                    Profile
                  </ListGroup.Item>

                  {auth.hasPermission('Manage users and roles') ? (
                    <ListGroup.Item
                      className={`${currentPage === usersKey && !isMobile() ? 'linkActive' : ''} text-left`}
                      action
                      as={Link}
                      to={`/settings/users`}
                      onClick={() => {
                        setCurrentPage(usersKey);
                        setSettingSelected(true);
                      }}
                    >
                      Users
                    </ListGroup.Item>
                  ) : null}

                  {auth.hasPermission('Manage payment') ? (
                    <ListGroup.Item
                      className={`${currentPage === billingKey && !isMobile() ? 'linkActive' : ''} text-left`}
                      action
                      as={Link}
                      to={`/settings/billing`}
                      onClick={() => {
                        setCurrentPage(billingKey);
                        setSettingSelected(true);
                      }}
                    >
                      Billing
                    </ListGroup.Item>
                  ) : null}

                  {auth.hasPermission('Manage payment') ? (
                    <ListGroup.Item
                      className={`${currentPage === accountKey && !isMobile() ? 'linkActive' : ''} text-left`}
                      action
                      as={Link}
                      to={`/settings/account`}
                      onClick={() => {
                        setCurrentPage(accountKey);
                        setSettingSelected(true);
                      }}
                    >
                      Account
                    </ListGroup.Item>
                  ) : null}

                  {auth.hasPermission('Manage users and roles') ? (
                    <ListGroup.Item
                      className={`${currentPage === rolesKey && !isMobile() ? 'linkActive' : ''} text-left`}
                      action
                      as={Link}
                      to={`/settings/roles`}
                      onClick={() => {
                        setCurrentPage(rolesKey);
                        setSettingSelected(true);
                      }}
                    >
                      Roles
                    </ListGroup.Item>
                  ) : null}

                  <ListGroup.Item
                    className={`${currentPage === hospitalsKey && !isMobile() ? 'linkActive' : ''} text-left`}
                    action
                    as={Link}
                    to={`/settings/hospitals`}
                    onClick={() => {
                      setCurrentPage(hospitalsKey);
                      setSettingSelected(true);
                    }}
                  >
                    Hospitals
                  </ListGroup.Item>

                  <ListGroup.Item
                    className={`${currentPage === referralsKey && !isMobile() ? 'linkActive' : ''} text-left`}
                    action
                    as={Link}
                    to={`/settings/referrals`}
                    onClick={() => {
                      setCurrentPage(referralsKey);
                      setSettingSelected(true);
                    }}
                  >
                    Referrals
                  </ListGroup.Item>

                  <ListGroup.Item className={`text-left`} action as={Link} to={`/logout`}>
                    Log out
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            ) : null}

            {!isMobile() || (isMobile() && settingSelected) ? (
              <Col sm={12} lg={10}>
                {isMobile() ? (
                  <Row>
                    <Col className="text-left py-2">
                      <BackButton to="/settings">Settings</BackButton>
                    </Col>
                  </Row>
                ) : null}
                {getSettingsPage(currentPage)}
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;
