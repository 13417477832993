import { Formik } from 'formik';
import React, { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import * as Yup from 'yup';
import { useAuth } from './AuthProvider';
import { User } from './Users';
import { handleError } from '../helper/error';
import { Button } from './ui/Button';
import { AxiosResponse } from 'axios';
import SettingsCard from './SettingsCard';
import { FormTextInput } from './ui/FormTextInput';
import { Spinner } from './ui/Spinner';
import SuccessAlert from './SuccessAlert';
import './login.css';

const Profile: React.FC = () => {
  const auth = useAuth();
  const [error, setError] = useState<string | undefined>(undefined);
  const [user, setUser] = useState<User>();
  const [success, setSuccess] = useState<string>();
  const [sendingResetEmail, setSendingResetEmail] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const refreshUser = useCallback(async () => {
    try {
      const res: AxiosResponse<User> = await auth.Get(`/api/v1/users/${auth.user?.id}`);
      setUser(res.data);
      setIsLoading(false);
    } catch (err) {
      handleError(err, setError);
      setIsLoading(false);
    }
  }, [auth]);

  useEffect(() => {
    refreshUser();
  }, [refreshUser]);

  // Schemas for yup
  const profileValidationSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'First name must have at least 2 characters').required('First name is required'),
    lastName: Yup.string().min(2, 'Last name must have at least 2 characters').required('Last name is required'),
  });

  return (
    <SettingsCard title="Profile" error={error} setError={setError}>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <SuccessAlert message={success} />

          {user ? (
            <div>
              <Formik
                initialValues={{
                  firstName: user.firstName,
                  lastName: user.lastName,
                }}
                validationSchema={profileValidationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const updatedUser: User = {
                      id: user.id,
                      groupId: user.groupId,
                      roleId: user.roleId,
                      isDoctor: user.isDoctor,
                      lastName: values.lastName,
                      firstName: values.firstName,
                      email: user.email,
                    };

                    await auth.Put(`/api/v1/users/${user.id}`, updatedUser);
                    setSubmitting(false);
                    refreshUser();
                    setSuccess('Profile successfully updated!');

                    setTimeout(() => {
                      setSuccess(undefined);
                    }, 5000);

                    // TODO: Figure out if this is dangerous.
                    // clearTimeout(timer);
                  } catch (err) {
                    handleError(err, setError);
                    setSubmitting(false);
                  }
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormTextInput
                      name="firstName"
                      header="First name"
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />

                    <FormTextInput
                      name="lastName"
                      header="Last name"
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />

                    <Row>
                      <Col>
                        <Button type="submit" spinner={isSubmitting}>
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>

              <Row className="mt-2">
                <Col>
                  <Button
                    variant="custom-secondary"
                    onClick={async () => {
                      setSendingResetEmail(true);
                      try {
                        await axios.post(`/api/send-password-reset`, {
                          email: user.email,
                        });
                        setSendingResetEmail(false);
                        setSuccess('An email with instructions for resetting your password has been sent to you!');

                        setTimeout(() => {
                          setSuccess(undefined);
                        }, 5000);

                        // TODO: Figure out if this is dangerous.
                        // clearTimeout(timer);
                      } catch (err) {
                        handleError(err, setError);
                        setSendingResetEmail(false);
                      }
                    }}
                    spinner={sendingResetEmail}
                  >
                    Request Password Reset Email
                  </Button>
                </Col>
              </Row>
            </div>
          ) : null}
        </div>
      )}
    </SettingsCard>
  );
};

export default Profile;
