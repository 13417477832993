import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import DisplayError from './DisplayError';
import { useIsMobile } from './MobileProvider';
import './login.css';

type Props = {
  title: string;
  error?: string;
  setError?: Function;
};

const LoginFlowCard: React.FC<Props> = ({ title, error, setError, children }) => {
  const { isMobile } = useIsMobile();

  return (
    <Container>
      <Row className="justify-content-center">
        <Col sm={12} md={9} lg={7}>
          <Container className={`loginContainer ${isMobile() ? 'px-4 py-5' : 'p-5'}`}>
            <Row className="mb-3">
              <Col className="h3 text-left cardTitle">{title}</Col>
            </Row>

            <Row>
              <Col>{error && setError && <DisplayError error={error} setError={setError}></DisplayError>}</Col>
            </Row>

            {children}
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginFlowCard;
