import React, { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import { useAuth } from './AuthProvider';
import { Link } from 'react-router-dom';
import { Button } from './ui/Button';
import { handleError } from '../helper/error';
import LoginFlowCard from './LoginFlowCard';
import { FormTextInput } from './ui/FormTextInput';
import ReactGA from 'react-ga';
import './login.css';

const Login: React.FC = () => {
  const [error, setError] = useState<string>();
  const history = useHistory();
  const auth = useAuth();

  // Google analytics tracking.
  ReactGA.pageview(window.location.pathname + window.location.search, undefined, 'Login');

  return (
    <LoginFlowCard title="Login" error={error} setError={setError}>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);

          try {
            await auth.login(values.email, values.password);
            resetForm();
            setSubmitting(false);
            history.push('/notes');
          } catch (err) {
            setSubmitting(false);
            handleError(err, setError);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormTextInput
              name="email"
              header="Email"
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            />

            <FormTextInput
              name="password"
              inputType="password"
              header="Password"
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            />

            <Row>
              <Col className="text-right forgotPasswordLink mb-2">
                <Link to={`/forgot`}>Forgot your password?</Link>
              </Col>
            </Row>

            <Row className="my-2 justify-content-center">
              <Col md={5}>
                <Button style={{ fontSize: 20, height: 50 }} block type="submit" spinner={isSubmitting}>
                  Login
                </Button>
              </Col>
            </Row>

            <Row className="justify-content-center mt-3">
              <Col>
                <Link to={`/install`}>Install a Home Screen shortcut for an app experience!</Link>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </LoginFlowCard>
  );
};

export default Login;
