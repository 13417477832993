import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import logo from '../images/mainLogo.png';
import './noPatientSelected.css';

const NoPatientSelected: React.FC = () => {
  return (
    <Container className="noPatientContainer">
      <Row className="h-100">
        <Col className="my-auto">
          <Row>
            <Col>
              <Image style={{ maxHeight: 60, maxWidth: '100%' }} src={logo} fluid />
            </Col>
          </Row>
          <Row>
            <Col className="h6 mt-2">Please select a patient.</Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default NoPatientSelected;
