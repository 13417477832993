import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, ListGroup, Container } from 'react-bootstrap';
import PatientModal from './PatientModal';
import { AxiosResponse } from 'axios';
import { useAuth } from './AuthProvider';
import DisplayError from './DisplayError';
import { handleError } from '../helper/error';
import { Hospital } from './Hospitals';
import { User } from './Users';
import PatientNotes from './PatientNotes';
import PatientItem from './PatientItem';
import PatientFilters from './PatientFilters';
import { Note } from './PatientNotes';
import NoPatientSelected from './NoPatientSelected';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useIsMobile } from './MobileProvider';
import BackButton from './ui/BackButton';
import { Spinner } from './ui/Spinner';
import { ModalNote } from './NewNote';
import { demoPatients, demoHospitals, demoNotes, demoUsers } from '../helper/demo';
import ReactGA from 'react-ga';
import './main.css';

const DEMODATA = 'demoData';

export type Patient = {
  id?: string; // Not present if adding new patient.
  groupId?: string;
  lastName: string;
  firstName: string;
  hospitalId: string;
  floor: string;
  doctorId: string;
  diagnosis: string;
};

export type NotesByPatientId = {
  [key: string]: Note[];
};

type UrlParams = {
  patientId?: string;
};

export const getHospitalName = (id: string, hospitals: Hospital[]) => {
  const hospital = hospitals.find((h) => h.id === id);
  return hospital?.name;
};

export const getUserLastName = (id: string, users: User[]) => {
  const doctor = users.find((d) => d.id === id);
  return doctor?.lastName;
};

type Data = {
  patients: Patient[];
  filteredPatients: Patient[];
  hospitals: Hospital[];
  users: User[];
  notes: NotesByPatientId;
  isLoading: boolean;
};

const Main: React.FC = () => {
  const [error, setError] = useState<string>();
  const [currentPatient, setCurrentPatient] = useState<string>();
  const [showPatientModal, setShowPatientModal] = useState<boolean>(false);
  const [editPatient, setEditPatient] = useState<Patient | undefined>(undefined);
  const { patientId } = useParams<UrlParams>();
  const history = useHistory();
  const { Get, Delete, Put, Post, user } = useAuth();
  const { isMobile } = useIsMobile();
  const [patientSelected, setPatientSelected] = useState<boolean>(false); // This is used for mobile.
  const isDemo = useLocation().pathname.startsWith('/demo'); // Used for demo.

  // Google analytics tracking.
  if (isDemo) {
    ReactGA.pageview(window.location.pathname + window.location.search, undefined, 'Demo');
  } else {
    ReactGA.pageview(window.location.pathname + window.location.search, undefined, 'Main');
  }

  const [data, setData] = useState<Data>({
    patients: [],
    filteredPatients: [],
    hospitals: [],
    users: [],
    notes: {},
    isLoading: true,
  });

  // If this is demo, use default demo data.
  if (isDemo) {
    localStorage.setItem(
      DEMODATA,
      JSON.stringify({
        patients: demoPatients,
        filteredPatients: demoPatients,
        hospitals: demoHospitals,
        users: demoUsers,
        notes: demoNotes,
        isLoading: false,
      })
    );
  }

  const getLoggedInUserId = () => {
    if (isDemo) {
      return data.users[0].id;
    } else {
      if (!user) {
        setError('Unable to detect logged in user. Please log in.');
        return '0';
      } else {
        return user.id;
      }
    }
  };

  const refreshTable = useCallback(async () => {
    if (isDemo) {
      const demoData = localStorage.getItem(DEMODATA);
      if (demoData) {
        setData(JSON.parse(demoData));
      }
      return;
    }

    try {
      const promises: Promise<AxiosResponse>[] = [
        Get(`/api/v1/patients`),
        Get(`/api/v1/hospitals`),
        Get(`/api/v1/users`),
        Get(`/api/v1/notes`),
      ];

      const [patientResponse, hospitalResponse, userResponse, notesResponse] = await Promise.all(promises);
      setData({
        patients: patientResponse.data,
        filteredPatients: patientResponse.data,
        hospitals: hospitalResponse.data,
        users: userResponse.data,
        notes: notesResponse.data,
        isLoading: false,
      });
    } catch (err) {
      handleError(err, setError);
      setData((data) => {
        return {
          ...data,
          isLoading: false,
        };
      });
    }
  }, [Get, isDemo]);

  const sortPatients = useCallback(
    (patients) => {
      return patients.sort((a: Patient, b: Patient) => {
        // sort by hospital first
        const hospA = data.hospitals.find((h) => h.id === a.hospitalId);
        const hospB = data.hospitals.find((h) => h.id === b.hospitalId);
        if (hospA?.name.localeCompare(hospB?.name || '') !== 0) {
          return hospA?.name.localeCompare(hospB?.name || '') || 0;
        }
        // sort by patient name second
        return a.lastName.localeCompare(b.lastName);
      });
    },
    [data.hospitals]
  );

  useEffect(() => {
    refreshTable();
  }, [refreshTable]);

  useEffect(() => {
    if (patientId) {
      setCurrentPatient(patientId);
      setPatientSelected(true); // Indicates that we should be on the patient notes view.
    } else {
      if (isMobile()) {
        setPatientSelected(false);
      }
    }
  }, [patientId, isMobile]);

  const deletePatient = async (id?: string) => {
    if (isDemo) {
      const patients = data.patients;
      const deleteIndex = patients.findIndex((p) => p.id === id);
      patients.splice(deleteIndex, 1);

      localStorage.setItem(
        DEMODATA,
        JSON.stringify({
          ...data,
          patients: patients,
          filteredPatients: patients,
        })
      );

      history.push(`/demo`);
      refreshTable();
      setCurrentPatient(undefined);
      return;
    }

    try {
      await Delete(`/api/v1/patients/${id}`);
      history.push(`/notes`);
      refreshTable();
      setCurrentPatient(undefined);
    } catch (err) {
      handleError(err, setError);
    }
  };

  const deleteNote = async (id: string) => {
    if (isDemo) {
      if (currentPatient) {
        const allNotes = data.notes;
        const patientNotes = allNotes[currentPatient];
        const deleteIndex = patientNotes.findIndex((n) => n.id === id);
        patientNotes.splice(deleteIndex, 1);
        allNotes[currentPatient] = patientNotes;

        localStorage.setItem(
          DEMODATA,
          JSON.stringify({
            ...data,
            notes: allNotes,
          })
        );

        refreshTable();
      }

      return;
    }

    try {
      await Delete(`/api/v1/notes/${id}`);
      refreshTable();
    } catch (err) {
      handleError(err, setError);
    }
  };

  const createNote = async (note: ModalNote) => {
    if (isDemo) {
      if (currentPatient) {
        data.notes[currentPatient] = [
          {
            id: Math.floor(Math.random() * 1000).toString(),
            ...note,
          },
          ...data.notes[currentPatient],
        ];

        localStorage.setItem(
          DEMODATA,
          JSON.stringify({
            ...data,
            notes: data.notes,
          })
        );
      }

      return;
    }

    try {
      await Post(`/api/v1/notes`, note);
    } catch (err) {
      handleError(err, setError);
    }
  };

  const createOrUpdatePatient = async (editPatient: Patient, patient: Patient) => {
    if (isDemo) {
      if (editPatient) {
        const currentPatients = data.patients;
        const editIndex = currentPatients.findIndex((p) => p.id === editPatient.id);
        currentPatients[editIndex] = patient;

        localStorage.setItem(
          DEMODATA,
          JSON.stringify({
            ...data,
            patients: currentPatients,
          })
        );
      } else {
        const newPatientId = Math.floor(Math.random() * 1000).toString();

        localStorage.setItem(
          DEMODATA,
          JSON.stringify({
            ...data,
            patients: [
              ...data.patients,
              {
                ...patient,
                id: newPatientId,
                groupId: data.patients[0].groupId, // Make sure groupId matches.
              },
            ],
            filteredPatients: data.patients,
            notes: {
              // Add empty notes array for newly added patient.
              ...data.notes,
              [newPatientId]: [],
            },
          })
        );

        // history.push(`/demo/${newPatientId}`);
      }

      return;
    }

    try {
      if (editPatient) {
        await Put(`/api/v1/patients/${editPatient.id}`, patient);
      } else {
        const response = await Post(`/api/v1/patients`, patient);
        history.push(`/notes/${response.data.id}`);
      }
    } catch (err) {
      handleError(err, setError);
    }
  };

  const getPatientNotes = (patientId?: string): Note[] => {
    return patientId ? (data.notes[patientId] ? data.notes[patientId] : []) : [];
  };

  const handleEditPatient = (patient: Patient) => {
    setEditPatient(patient);
    setShowPatientModal(true);
  };

  const setFilteredPatients = useCallback(
    (filteredPatients: Patient[]) => {
      setData((data) => {
        return {
          ...data,
          filteredPatients,
        };
      });
    },
    [setData]
  );

  return (
    <Container>
      {error && <DisplayError sm={12} lg={7} error={error} setError={setError}></DisplayError>}

      {isDemo && isMobile() && (
        <Row className="justify-content-center mb-2">
          <Col xl={6}>
            <div style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: 22 }}>
              <b>
                <em>Demo</em>
              </b>
            </div>
          </Col>
        </Row>
      )}

      {/* Back button for mobile */}
      {isMobile() && patientSelected ? (
        <Row>
          <Col className="text-left py-2">
            <BackButton to={isDemo ? '/demo' : '/notes'}>Patients</BackButton>
          </Col>
        </Row>
      ) : null}

      <Row>
        {!isMobile() || (isMobile() && !patientSelected) ? (
          <Col sm={12} lg={4}>
            <Col className="mainColumn pb-3">
              <PatientFilters
                patients={data.patients}
                filteredPatients={data.filteredPatients}
                doctors={data.users.filter((u) => u.isDoctor)}
                hospitals={data.hospitals}
                setFilteredPatients={setFilteredPatients}
                setShowPatientModal={setShowPatientModal}
              />

              {data.isLoading ? (
                <Spinner />
              ) : (
                <div>
                  {data.filteredPatients.length === 0 ? (
                    <Row>
                      <Col>No patients to view.</Col>
                    </Row>
                  ) : null}

                  <ListGroup>
                    {sortPatients(data.filteredPatients).map((patient: Patient) => {
                      return (
                        <PatientItem
                          key={patient.id}
                          patient={patient}
                          doctorName={getUserLastName(patient.doctorId, data.users)}
                          hospitalName={getHospitalName(patient.hospitalId, data.hospitals)}
                          currentPatient={currentPatient}
                          linkTo={isDemo ? `/demo/${patient.id}` : `/notes/${patient.id}`}
                          onClick={() => {
                            setCurrentPatient(patient.id);
                            setPatientSelected(true);
                          }}
                        />
                      );
                    })}
                  </ListGroup>
                </div>
              )}
            </Col>
          </Col>
        ) : null}

        {!isMobile() || (isMobile() && patientSelected) ? (
          <Col sm={12} lg={8}>
            <Col className="mainColumn pb-3">
              {currentPatient ? (
                data.patients
                  .filter((p) => p.id === currentPatient)
                  .map((patient) => {
                    return (
                      <PatientNotes
                        key={patient.id}
                        patient={patient}
                        notes={getPatientNotes(patient.id)}
                        users={data.users}
                        hospitals={data.hospitals}
                        refreshTable={refreshTable}
                        deletePatient={deletePatient}
                        createNote={createNote}
                        deleteNote={deleteNote}
                        handleEditPatient={handleEditPatient}
                        userId={getLoggedInUserId()}
                        setError={setError}
                      />
                    );
                  })
              ) : (
                <NoPatientSelected />
              )}
            </Col>
          </Col>
        ) : null}
      </Row>

      <PatientModal
        showModal={showPatientModal}
        setShowModal={setShowPatientModal}
        refreshTable={refreshTable}
        editPatient={editPatient}
        setEditPatient={setEditPatient}
        createOrUpdatePatient={createOrUpdatePatient}
        doctors={data.users.filter((u) => u.isDoctor)}
        hospitals={data.hospitals}
      />
    </Container>
  );
};

export default Main;
