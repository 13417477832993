import React from 'react';
import { Col, Row } from 'react-bootstrap';
import LoginFlowCard from './LoginFlowCard';
import ReactGA from 'react-ga';
import { useIsMobile } from './MobileProvider';
import './login.css';

const Contact: React.FC = () => {
  const { isMobile } = useIsMobile();
  // Google analytics tracking.
  ReactGA.pageview(window.location.pathname + window.location.search, undefined, 'Contact');

  return (
    <LoginFlowCard title="Contact us">
      <Row>
        <Col className="text-left">
          We would love to hear from you! Please let us know if you have any questions, concerns, or comments. You can
          always reach us at:
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <a
            style={{ color: '#ff15b0', textDecoration: 'none', fontSize: isMobile() ? '1.5rem' : '2.0rem' }}
            href="mailto:hello@roundnotes.com"
          >
            <b>hello@roundnotes.com</b>
          </a>
        </Col>
      </Row>
    </LoginFlowCard>
  );
};

export default Contact;
