import { Container, Row, Col, Image, Card } from 'react-bootstrap';
import { useIsMobile } from './MobileProvider';
import ReactGA from 'react-ga';
import { Button } from './ui/Button';
import { Link } from 'react-router-dom';
import notesListPic from '../images/demo/notes-list.webp';
import noteDemoPic from '../images/demo/note-close.webp';
import mobileDesktopPic from '../images/demo/mobile-desktop.webp';
import abaid from '../images/headshots/abaid.webp';
import beck from '../images/headshots/beck.webp';
import brown from '../images/headshots/brown.webp';
import './main.css';

type LandingProps = {
  header?: string;
  subheader?: string;
};

type SalesMessageProps = {
  header: string;
  image?: string;
  imageWidth?: string;
  imageSide?: 'left' | 'right';
  imageColumnWidth?: number;
};

const SalesMessage: React.FC<SalesMessageProps> = ({
  children,
  header,
  image,
  imageWidth,
  imageSide,
  imageColumnWidth,
}) => {
  const { isMobile } = useIsMobile();

  if (!isMobile()) {
    return (
      <Container className="mainColumn mb-5 py-4 px-5">
        <Row className="mb-4 mt-3">
          {imageSide === 'left' ? (
            <Col md={imageColumnWidth} lg={imageColumnWidth} xl={imageColumnWidth} className="text-center my-auto">
              <Image width={imageWidth} src={image} />
            </Col>
          ) : null}

          <Col className="text-left my-auto">
            <Row>
              <Col className="h1" style={{ fontSize: 45 }}>
                <b>{header}</b>
              </Col>
            </Row>

            <Row className="mt-2">
              <Col style={{ fontSize: '1.2rem' }}>{children}</Col>
            </Row>
          </Col>

          {imageSide === 'right' ? (
            <Col md={imageColumnWidth} lg={imageColumnWidth} xl={imageColumnWidth} className="text-center my-auto">
              <Image width={imageWidth} src={image} />
            </Col>
          ) : null}
        </Row>
      </Container>
    );
  } else {
    return (
      <Container className="mainColumn mb-5 py-4 px-4">
        <div className="text-left">
          <Row>
            <Col className="h1" style={{ fontSize: 45 }}>
              <b>{header}</b>
            </Col>
          </Row>

          <Row className="mt-1 mb-3">
            <Col style={{ fontSize: '1.4rem' }}>{children}</Col>
          </Row>
        </div>
      </Container>
    );
  }
};

type MobileImageProps = {
  image: string;
  width: string;
};

const MobileImage: React.FC<MobileImageProps> = ({ image, width }) => {
  const { isMobile } = useIsMobile();
  return isMobile() ? <Image className="mb-5" src={image} width={width} /> : null;
};

type TestimonialProps = {
  name: string;
  role?: string;
  location?: string;
  image?: string;
};

const Testimonial: React.FC<TestimonialProps> = ({ children, name, role, location, image }) => {
  return (
    <Card border="light" style={{ width: '16rem' }}>
      {image ? (
        <div className="text-center">
          <Card.Img style={{ width: '75%', borderRadius: '50%' }} variant="top" src={image} />
        </div>
      ) : null}

      <Card.Body>
        <div className="mb-2">
          <Card.Title style={{ fontSize: 20, marginBottom: 0 }}>{name}</Card.Title>
          {role && (
            <Card.Title style={{ fontSize: 13, marginBottom: 0, color: 'rgba(0, 0, 0, 0.5)' }}>{role}</Card.Title>
          )}
          {location && (
            <Card.Title style={{ fontSize: 13, color: 'rgba(0, 0, 0, 0.5)' }} className="mb-2">
              {location}
            </Card.Title>
          )}
        </div>
        <Card.Text style={{ fontSize: 16 }} className="text-left">
          <em>"{children}"</em>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const Landing: React.FC<LandingProps> = ({ header, subheader }) => {
  const { isMobile } = useIsMobile();

  // Google analytics tracking.
  ReactGA.pageview(window.location.pathname + window.location.search, undefined, 'Landing page');

  return (
    <Container>
      <Row>
        <Col sm={12} md={12} lg={12} xl={11} className="mx-auto">
          <Container className={`mainColumn mb-5 py-4 ${!isMobile() ? 'px-5 mt-4' : 'px-4'}`}>
            <Row className="mt-3">
              <Col className="text-left h1">
                <h1>
                  <b style={{ fontSize: '3.0rem' }}>{header ? header : 'Collaborative patient notes made easy.'}</b>
                </h1>
              </Col>
            </Row>

            <Row className={`${isMobile() ? 'mt-1 mb-4' : 'mt-1 mb-3'}`}>
              <Col className="text-left" style={{ fontSize: '1.4rem' }}>
                {subheader
                  ? subheader
                  : `Roundnotes is a simple tool for sharing patient notes with other providers in your organization. It can
                help you organize rounds and ensure seamless, efficient communication between providers, improving
                safety and patient care.`}
              </Col>
            </Row>
          </Container>

          <Container className={`mb-5 ${isMobile() ? 'py-3' : ''} px-4`}>
            <Row>
              <Col>
                <Link
                  style={{ textDecoration: 'none' }}
                  to={isMobile() ? '/demo' : '/demo/yogi'}
                  onClick={() => {
                    ReactGA.event({
                      category: 'Landing',
                      action: 'Demo Click',
                    });
                  }}
                >
                  <Button block variant="custom-primary" style={{ fontSize: 20, height: 50 }}>
                    Take a Tour!
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>

          <MobileImage image={notesListPic} width="100%" />

          <SalesMessage
            header="Instant visibility without extra complexity."
            image={notesListPic}
            imageWidth="100%"
            imageSide="left"
            imageColumnWidth={6}
          >
            Turn patient care into a conversation with your peers, with a single platform where any notes from any
            provider are stored and instantly accessible by any other provider in your group.
          </SalesMessage>

          <MobileImage image={noteDemoPic} width="100%" />

          <SalesMessage
            header="Make note of what matters to your patient."
            image={noteDemoPic}
            imageWidth="100%"
            imageSide="right"
            imageColumnWidth={6}
          >
            EMRs are vital, but don't capture everything that matters. Fill in the gaps to deliver a consistent and
            remarkable patient experience.
          </SalesMessage>

          <MobileImage image={mobileDesktopPic} width="100%" />

          <SalesMessage
            header="Ready for mobile or desktop."
            image={mobileDesktopPic}
            imageWidth="90%"
            imageSide="left"
            imageColumnWidth={7}
          >
            Have the freedom to work from your phone or your desktop. Roundnotes was designed from the ground up with
            both in mind.
          </SalesMessage>

          <SalesMessage header="Secure and HIPAA-Compliant.">
            Roundnotes is fully HIPAA-compliant and employs encryption for protected health information in-transit and
            at-rest. We will never share information about providers or patients associated with your organization.
          </SalesMessage>

          <Container className={`mainColumn mb-5 py-4 ${!isMobile() ? 'px-5 mt-4' : 'px-4'}`}>
            <Row className="mt-3">
              <Col className={`h1 ${isMobile() ? 'text-left mb-4' : 'text-left mb-3'} `} style={{ fontSize: 45 }}>
                <b>Testimonials</b>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col>
                <Testimonial name="Lisa N. Abaid, MD" image={abaid}>
                  Roundnotes is a great way to manage multiple patients with multiple providers across multiple hospital
                  systems. It helps us organize rounds and ensure seamless, efficient communication between providers,
                  improving safety and patient care. We love it!
                </Testimonial>
              </Col>

              <Col>
                <Testimonial name="Tiffany L. Beck, MD" image={beck}>
                  With Roundnotes, we have one easily managed patient list that is efficient, secure, and works in
                  real-time. The team behind Roundnotes is also very responsive whenever we have questions or need
                  adjustments. Highly recommend!
                </Testimonial>
              </Col>

              <Col>
                <Testimonial name="John V. Brown, MD" image={brown}>
                  Roundnotes allows multiple providers to very easily hand off relevant patient information to one
                  another. There is no doubt that this improves the care that we provide to our patients.
                </Testimonial>
              </Col>
            </Row>
          </Container>

          <Container className="mainColumn mb-3 py-4 px-5">
            <Row>
              <Col className="text-center">
                <Row>
                  <Col className="h1" style={{ fontSize: 45 }}>
                    <b>Questions?</b>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col style={isMobile() ? { fontSize: 22 } : { fontSize: 30 }}>
                    Contact us:{' '}
                    <a style={{ color: '#ff15b0', textDecoration: 'none' }} href="mailto:hello@roundnotes.com">
                      <b>hello@roundnotes.com</b>
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Landing;
