import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { Button } from './ui/Button';
import * as Yup from 'yup';
import './modal.css';
import DisplayError from './DisplayError';
import { handleError } from '../helper/error';

export type ModalNote = {
  patientId: string;
  text: string;
  createdById: string;
};

type Props = {
  patientId: string;
  createNote: Function;
  userId: string;
  refreshTable: Function;
  setOpenNoteInput: Function;
};

const NewNote: React.FC<Props> = ({ patientId, createNote, userId, refreshTable, setOpenNoteInput }) => {
  const [error, setError] = useState<string>();

  // Schema for yup
  const validationSchema = Yup.object().shape({
    text: Yup.string().min(1, 'Text is required').required('Text is required'),
  });

  return (
    <div>
      {error && <DisplayError error={error} setError={setError} />}

      <Formik
        initialValues={{ text: '' }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);

          try {
            const note: ModalNote = {
              text: values.text,
              patientId: patientId,
              createdById: userId,
            };

            await createNote(note);
            resetForm();
            setSubmitting(false);
            refreshTable();
            setOpenNoteInput(false);
          } catch (err) {
            setSubmitting(false);
            handleError(err, setError);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mt-2" controlId="formNote">
              <Form.Control
                as="textarea"
                rows={4}
                name="text"
                placeholder="What's going on?"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.text}
                className={touched.text && errors.text ? 'errorFormInput' : undefined}
              />
              {touched.text && errors.text && <div className="errorMessage">{errors.text}</div>}
            </Form.Group>
            <Button className="mx-1" type="submit" spinner={isSubmitting}>
              Save
            </Button>
            <Button className="mx-1" variant="custom-secondary" onClick={() => setOpenNoteInput(false)}>
              Close
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewNote;
