import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Form, Row, Col, OverlayTrigger, Popover, Table } from 'react-bootstrap';
import { useAuth } from './AuthProvider';
import SettingsCard from './SettingsCard';
import * as Yup from 'yup';
import { Spinner } from './ui/Spinner';
import { handleError } from '../helper/error';
import { FormTextInput } from './ui/FormTextInput';
import { Formik } from 'formik';
import { Button } from './ui/Button';
import { useIsMobile } from './MobileProvider';
import { QuestionCircle } from 'react-bootstrap-icons';
import SuccessAlert from './SuccessAlert';

export type ReferralLink = {
  referralLink: string;
};

export type Group = {
  id: string;
  name: string;
  createdAt: string;
};

const Referrals: React.FC = () => {
  const [referralLink, setReferralLink] = useState<string>();
  const [referredGroups, setReferredGroups] = useState<Group[]>();
  const [error, setError] = useState<string>();
  const { Get, Post } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<string>();
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  const { isMobile } = useIsMobile();

  const formatDate = (d: Date) => {
    return d.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  };

  const getReferralLink = useCallback(async () => {
    try {
      const promises: Promise<AxiosResponse>[] = [Get(`/api/v1/referrals/link`), Get(`/api/v1/referrals/groups`)];
      const [referralLinkResponse, referredGroupsResponse] = await Promise.all(promises);

      setReferralLink(referralLinkResponse.data.referralLink);
      setReferredGroups(referredGroupsResponse.data);
      setIsLoading(false);
    } catch (err) {
      handleError(err, setError);
      setIsLoading(false);
    }
  }, [Get]);

  useEffect(() => {
    getReferralLink();
  }, [getReferralLink]);

  const linkCopiedPopover = (
    <Popover id="popover-basic">
      <Popover.Content>Link copied!</Popover.Content>
    </Popover>
  );

  const referredOrgExplanationPopover = (
    <Popover id="popover-basic">
      <Popover.Content>
        These are the organizations that your organization has referred, along with the date they signed up.
      </Popover.Content>
    </Popover>
  );

  // Schemas for yup
  const emailLinkValidationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
  });

  return (
    <SettingsCard title="Referrals" error={error} setError={setError}>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Row>
            <Col className="text-left h5 mt-3">Refer an Organization, Receive $50</Col>
          </Row>

          <hr className="mt-1 mb-2" />

          <Row className="mb-3 text-left">
            <Col>
              You can refer another organization by emailing the first user using this form or by simply copying the
              referral link and giving it to them in another way. When the user from the new organization signs up, your
              organization will receive $50, which will be deducted from your next payment!
            </Col>
          </Row>

          <Row className="mb-4 text-left font-weight-bold">
            <Col>
              Remember that you only need to invite one user from the organization, and they will invite their
              associated users.
            </Col>
          </Row>

          <SuccessAlert message={success} />

          <Row className="justify-content-left mb-2">
            <Col md={9} lg={9}>
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={emailLinkValidationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    await Post(`/api/v1/referrals/email`, {
                      email: values.email,
                    });
                    setSubmitting(false);
                    setSuccess('Referral email sent!');

                    setTimeout(() => {
                      setSuccess(undefined);
                    }, 5000);

                    // TODO: Figure out if this is dangerous.
                    // clearTimeout(timer);
                  } catch (err) {
                    handleError(err, setError);
                    setSubmitting(false);
                  }
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <FormTextInput
                      name="email"
                      header="Email"
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                    />

                    <Row>
                      <Col>
                        <Button block type="submit" spinner={isSubmitting}>
                          Send Email
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>

          {referralLink && (
            <Row className="justify-content-left mb-5">
              <Col md={9} lg={9}>
                <OverlayTrigger
                  placement={isMobile() ? 'bottom' : 'right'}
                  overlay={linkCopiedPopover}
                  show={showCopiedTooltip}
                >
                  <div>
                    <Button
                      block
                      variant="custom-secondary"
                      onClick={() => {
                        navigator.clipboard.writeText(referralLink);
                        setShowCopiedTooltip(true);

                        setTimeout(() => {
                          setShowCopiedTooltip(false);
                        }, 2000);
                      }}
                    >
                      Copy Referral Link
                    </Button>
                  </div>
                </OverlayTrigger>
              </Col>
            </Row>
          )}

          {referredGroups && referredGroups.length > 0 && (
            <>
              <Row>
                <Col className="text-left h5 mt-4">
                  <span>Referred Organizations</span>
                  <span className="ml-1">
                    <OverlayTrigger placement={isMobile() ? 'bottom' : 'right'} overlay={referredOrgExplanationPopover}>
                      <QuestionCircle size={17} />
                    </OverlayTrigger>
                  </span>
                </Col>
              </Row>

              <Table className="mt-2">
                <thead>
                  <tr>
                    <td className="text-left tableHeader">Organization</td>
                    <td className="text-left tableHeader">Date</td>
                  </tr>
                </thead>
                <tbody>
                  {referredGroups.map((g) => {
                    return (
                      <tr key={g.id}>
                        <td className="text-left">{g.name}</td>
                        <td className="text-left">{formatDate(new Date(g.createdAt))}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          )}
        </div>
      )}
    </SettingsCard>
  );
};

export default Referrals;
