import { Formik } from 'formik';
import React, { useState } from 'react';
import { Form, Row, Col, Container } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuth } from './AuthProvider';
import { handleError } from '../helper/error';
import { Button } from './ui/Button';
import { Link } from 'react-router-dom';
import { FormTextInput } from './ui/FormTextInput';
import { FormDropdown } from './ui/FormDropdown';
import { useIsMobile } from './MobileProvider';
import DisplayError from './DisplayError';
import { CheckCircleFill } from 'react-bootstrap-icons';
import ReactGA from 'react-ga';
import './login.css';
import './signup.css';

// A custom hook that builds on useLocation to parse the query string.
export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const decodeEmail = (encodedEmail: string | null): string => {
  if (!encodedEmail) {
    return '';
  }
  return Buffer.from(encodedEmail, 'base64').toString('utf8');
};

const Signup: React.FC = () => {
  const auth = useAuth();
  const history = useHistory();
  const [error, setError] = useState<string | undefined>(undefined);
  const { isMobile } = useIsMobile();

  // Google analytics tracking.
  ReactGA.pageview(window.location.pathname + window.location.search, undefined, 'Sign up');

  // Parse the query params.
  const query = useQuery();
  const email = decodeEmail(query.get('email'));
  // If this is a referral, store the referring user's id.
  const referralParam = query.get('referralGroupId');
  if (referralParam) {
    localStorage.setItem('referralGroupId', referralParam);
  }

  // Schema for yup
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'First name must have at least 2 characters').required('First name is required'),
    lastName: Yup.string().min(2, 'Last name must have at least 2 characters').required('Last name is required'),
    email: Yup.string().email().required('Email is required'),
    isDoctor: Yup.boolean().required(),
  });

  return (
    <Container>
      <Row className="justify-content-center">
        <Col>
          <Container className={`loginContainer ${isMobile() ? 'px-4 py-5' : 'p-5'}`}>
            <Row>
              <Col className={isMobile() ? 'price-mobile-border' : 'price-border'}>
                <Row>
                  <Col className="h3 text-center cardTitle">Start with one free provider!</Col>
                </Row>

                <Row className="mb-1">
                  <Col className="text-center" style={{ fontSize: !isMobile() ? 18 : 16, color: 'rgba(0,0,0,.5)' }}>
                    Single user is free forever, then:
                  </Col>
                </Row>

                {!isMobile() ? (
                  <div>
                    <Row>
                      <Col className="h1" style={{ fontSize: 45 }}>
                        <Row style={{ fontSize: 130 }}>
                          <Col className="text-center">
                            <b>$5</b>
                          </Col>
                        </Row>

                        <Row style={{ fontSize: 18 }}>
                          <Col className="text-center" style={{ color: 'rgba(0,0,0,.5)' }}>
                            per additional user per month
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <div className="text-center h3 mt-4">
                      <div className="" style={{ display: 'inline-block', textAlign: 'left' }}>
                        <Row className="my-2">
                          <Col>
                            <CheckCircleFill /> <em>Unlimited patients</em>
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col>
                            <CheckCircleFill /> <em>Unlimited notes</em>
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col>
                            <CheckCircleFill /> <em>Unlimited hospitals</em>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Row className="mt-2">
                    <Col xs={5}>
                      <Row>
                        <Col className="text-center" style={{ fontSize: 80 }}>
                          <b>$5</b>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="text-center" style={{ fontSize: 12, color: 'rgba(0,0,0,.5)' }}>
                          per user per month
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={7} className="my-auto">
                      <div className="text-left">
                        <Row className="my-2">
                          <Col>
                            <CheckCircleFill /> <em>Unlimited patients</em>
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col>
                            <CheckCircleFill /> <em>Unlimited notes</em>
                          </Col>
                        </Row>
                        <Row className="my-2">
                          <Col>
                            <CheckCircleFill /> <em>Unlimited hospitals</em>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                )}

                <div className={!isMobile() ? 'mt-4 mb-3' : 'mt-3 mb-4'} style={{ fontSize: isMobile() ? 12 : 18 }}>
                  <Row>
                    <Col>More than 25 users?</Col>
                  </Row>

                  <Row>
                    <Col>
                      Contact us:{' '}
                      <a style={{ color: '#ff15b0', textDecoration: 'none' }} href="mailto:business@roundnotes.com">
                        <b>business@roundnotes.com</b>
                      </a>
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col className={!isMobile() ? 'pl-5' : 'mt-5'}>
                <Row className="mb-3">
                  <Col className="h3 text-left cardTitle">Create your account</Col>
                </Row>

                <Row>
                  <Col>{error && setError && <DisplayError error={error} setError={setError}></DisplayError>}</Col>
                </Row>
                <Row>
                  <Col className="text-left mb-4">
                    We will never share your email. You will use it to log in, and we will use it only for official
                    communications.
                  </Col>
                </Row>

                <Formik
                  initialValues={{
                    email,
                    firstName: '',
                    lastName: '',
                    isDoctor: 0,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (values) => {
                    try {
                      await auth.Post(`/api/signup`, {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        isDoctor: !!values.isDoctor, // convert this to bool for api
                      });
                      history.push('/verify');

                      ReactGA.event({
                        category: 'User',
                        action: 'Sign up',
                      });

                      return;
                    } catch (err) {
                      handleError(err, setError);
                    }
                  }}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit}>
                      <FormTextInput
                        name="email"
                        header="Email"
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                      />

                      <FormTextInput
                        name="firstName"
                        header="First name"
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                      />

                      <FormTextInput
                        name="lastName"
                        header="Last name"
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                      />

                      <FormDropdown
                        name="isDoctor"
                        header="Are you a doctor?"
                        values={values}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        errors={errors}
                        hoverProps={{
                          hoverTitle: 'Doctors',
                          hoverText: `If a user is a doctor, patients can be assigned to them. All users can take notes, but only doctors can be assigned to patients.`,
                          placement: isMobile() ? 'top' : 'right',
                        }}
                      >
                        <option value={0}>No</option>
                        <option value={1}>Yes</option>
                      </FormDropdown>

                      <Row className="mt-2 mb-4 text-left">
                        <Col>
                          By creating an account, you agree to the <Link to={`/terms`}>Terms and Conditions</Link> and
                          the <Link to={`/privacy`}>Privacy Policy</Link>.
                        </Col>
                      </Row>

                      <Row className="my-2 justify-content-center">
                        <Col md={5}>
                          <Button style={{ fontSize: 20, height: 50 }} block type="submit" spinner={isSubmitting}>
                            Create
                          </Button>
                        </Col>
                      </Row>

                      <Row className="pt-1">
                        <Col>
                          <Link to={`/`}>Back</Link>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Signup;
