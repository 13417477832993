import React, { useEffect, useCallback, useState } from 'react';
import { AxiosResponse } from 'axios';
import RoleModal from './RoleModal';
import { Row, Col, ListGroup } from 'react-bootstrap';
import SettingsCard from './SettingsCard';
import { useAuth } from './AuthProvider';
import { Spinner } from './ui/Spinner';
import SettingsItem from './SettingsItem';
import { handleError } from '../helper/error';

export type Permission = 'Manage users and roles' | 'Manage payment' | 'Manage patient notes';

export type Role = {
  id: string;
  groupId: string;
  name: string;
  permissions: Permission[];
};

const Roles: React.FC = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { Get, Delete } = useAuth();

  const refreshTable = useCallback(async () => {
    try {
      const roleRes: AxiosResponse<Role[]> = await Get(`/api/v1/roles`);
      setRoles(roleRes.data);

      const permissionRes: AxiosResponse<string[]> = await Get(`/api/v1/permissions`);
      setPermissions(permissionRes.data);

      setIsLoading(false);
    } catch (err) {
      handleError(err, setError);
      setIsLoading(false);
    }
  }, [Get]);

  const getPermissions = useCallback(async () => {
    try {
      const permissionRes: AxiosResponse<string[]> = await Get(`/api/v1/permissions`);
      setPermissions(permissionRes.data);
    } catch (err) {
      handleError(err, setError);
    }
  }, [Get]);

  const deleteRole = async (id: string) => {
    try {
      await Delete(`/api/v1/roles/${id}`);
      refreshTable();
    } catch (err) {
      handleError(err, setError);
    }
  };

  useEffect(() => {
    refreshTable();
    getPermissions();
  }, [refreshTable, getPermissions]);

  return (
    <SettingsCard
      title="Roles"
      error={error}
      setError={setError}
      setShowModal={setShowModal}
      modalButtonText="Add Role"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <Row>
            <Col>
              <ListGroup>
                {roles.map((r) => {
                  return (
                    <SettingsItem
                      key={r.id}
                      itemId={r.id}
                      heading={r.name}
                      subHeading={r.permissions.join('. ') + '.'}
                      deleteConfirmText="Are you sure you want to delete this role?"
                      deleteItem={deleteRole}
                    />
                  );
                })}
              </ListGroup>
            </Col>
          </Row>
          <RoleModal
            permissions={permissions}
            showModal={showModal}
            setShowModal={setShowModal}
            refreshTable={refreshTable}
          />
        </div>
      )}
    </SettingsCard>
  );
};

export default Roles;
