import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { Role } from './Roles';
import * as Yup from 'yup';
import './modal.css';
import { useAuth } from './AuthProvider';
import DisplayError from './DisplayError';
import { handleError } from '../helper/error';
import { Button } from './ui/Button';
import { FormTextInput } from './ui/FormTextInput';
import { FormDropdown } from './ui/FormDropdown';
import { useIsMobile } from './MobileProvider';
import ReactGA from 'react-ga';

export type Invite = {
  email: string;
  roleId: string;
  isDoctor: boolean;
};

type Props = {
  roles: Role[];
  showModal: boolean;
  setShowModal: Function;
  refreshTable: Function;
  setSuccess: Function;
};

const InviteUserModal: React.FC<Props> = ({ roles, showModal, setShowModal, refreshTable, setSuccess }) => {
  const [error, setError] = useState<string>();
  const { Post } = useAuth();
  const { isMobile } = useIsMobile();

  const onClose = () => {
    setShowModal(false);
  };

  // Schema for yup
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email address')
      .max(100, 'Email must be less than 100 characters')
      .required('Email is required'),
    roleId: Yup.string().required('Role is required'),
    isDoctor: Yup.boolean(),
  });

  return (
    <Modal show={showModal} onHide={() => onClose()} animation={false} centered={isMobile()}>
      <Formik
        initialValues={{
          roleId: '',
          email: '',
          isDoctor: 0,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);

          const invite: Invite = {
            roleId: values.roleId,
            email: values.email,
            isDoctor: !!values.isDoctor, // convert this to bool for api
          };

          try {
            await Post(`/api/v1/invites`, invite);

            resetForm();
            setSubmitting(false);
            refreshTable();
            setShowModal(false);
            setSuccess('User invited!');

            setTimeout(() => {
              setSuccess(undefined);
            }, 5000);

            ReactGA.event({
              category: 'User',
              action: 'Invited user',
            });
          } catch (err) {
            handleError(err, setError);
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Invite User</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {error && <DisplayError error={error} setError={setError}></DisplayError>}

              <FormTextInput
                name="email"
                header="Email"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              />

              <FormDropdown
                name="roleId"
                header="Role"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              >
                <option value={''}>Role</option>
                {roles.map((r) => (
                  <option key={r.id} value={r.id}>
                    {r.name}
                  </option>
                ))}
              </FormDropdown>

              <FormDropdown
                name="isDoctor"
                header="Is this user a doctor?"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              >
                <option value={0}>No</option>
                <option value={1}>Yes</option>
              </FormDropdown>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="custom-primary" type="submit" spinner={isSubmitting}>
                Invite
              </Button>
              <Button variant="custom-secondary" onClick={() => onClose()}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default InviteUserModal;
