import React from 'react';
import { Alert, Row, Col } from 'react-bootstrap';
import './main.css';
import './terms.css';

type SuccessAlertProps = {
  message?: string;
};

const SuccessAlert: React.FC<SuccessAlertProps> = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <Row className="mb-3">
      <Col className="text-left">
        <Alert variant="success">{message}</Alert>
      </Col>
    </Row>
  );
};

export default SuccessAlert;
