// DEMO DATA.

export const demoPatients = [
  {
    id: 'yogi',
    groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
    firstName: 'Yogi',
    lastName: 'Bear',
    diagnosis: 'Very Hungry',
    hospitalId: '139e41dd-0ed1-4a46-97b0-7e1476bd3e07',
    floor: 'Fifth Floor',
    doctorId: 'bfe4a817-c763-4588-b613-838daf35bc0b',
    createdAt: '2022-02-22T17:51:32.024007Z',
    archivedAt: null,
  },
  {
    id: 'marvin',
    groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
    firstName: 'Marvin',
    lastName: 'Martian',
    diagnosis: 'Needs Oxygen',
    hospitalId: 'd915f155-0527-49f1-89a9-78d8b91d6e7b',
    floor: 'Third Floor',
    doctorId: '5d594285-900a-428e-92d9-e82b15ed5597',
    createdAt: '2022-02-22T17:51:32.550194Z',
    archivedAt: null,
  },
  {
    id: 'alvin',
    groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
    firstName: 'Alvin',
    lastName: 'Chipmunk',
    diagnosis: 'Too Small',
    hospitalId: '677ca175-2542-4335-b2ed-94bb58f1e857',
    floor: 'First Floor',
    doctorId: '9c4ff789-17e6-474b-b194-3bc1e2d216ec',
    createdAt: '2022-02-22T17:51:31.868903Z',
    archivedAt: null,
  },
];

export const demoUsers = [
  {
    id: 'bfe4a817-c763-4588-b613-838daf35bc0b',
    groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
    roleId: '29483d32-1f33-4d9c-a574-989133a80d21',
    isDoctor: true,
    firstName: 'Mike',
    lastName: 'Parker',
    email: 'r2d2@jedi.com',
    password: '$2a$10$Rt2a1w.aZWIfgEn54xahZuZ9BgnhNtVnZ6L.ajHRKfVwUXU/bw1qG',
    lastLogin: null,
    createdAt: '2022-02-22T17:51:31.781729Z',
    archivedAt: null,
  },
  {
    id: '9c4ff789-17e6-474b-b194-3bc1e2d216ec',
    groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
    roleId: '4d6abe97-bac2-409a-b7df-16ef02f5d030',
    isDoctor: true,
    firstName: 'Landon',
    lastName: 'Turner',
    email: 'jinn@jedi.com',
    password: '$2a$10$sztB2yxCYyMDbjjzQUiAJuldVVWpp.agX2aRh7.XEDQQcKmNer6/K',
    lastLogin: null,
    createdAt: '2022-02-22T17:51:31.287904Z',
    archivedAt: null,
  },
  {
    id: '5d594285-900a-428e-92d9-e82b15ed5597',
    groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
    roleId: '4d6abe97-bac2-409a-b7df-16ef02f5d030',
    isDoctor: true,
    firstName: 'Michael',
    lastName: 'Brown',
    email: 'obi@jedi.com',
    password: '$2a$10$paXXBbknO2C/Eg.fD7c6ju1J59KUXnCAyRTODZb.XL0QoKzxr4W.i',
    lastLogin: null,
    createdAt: '2022-02-22T17:51:31.702301Z',
    archivedAt: null,
  },
];

export const demoHospitals = [
  {
    id: '139e41dd-0ed1-4a46-97b0-7e1476bd3e07',
    groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
    name: 'LA Hospital',
    createdAt: '2022-02-22T17:51:31.806677Z',
    archivedAt: null,
  },
  {
    id: 'd915f155-0527-49f1-89a9-78d8b91d6e7b',
    groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
    name: 'SF Hospital',
    createdAt: '2022-02-22T17:51:31.812027Z',
    archivedAt: null,
  },
  {
    id: '677ca175-2542-4335-b2ed-94bb58f1e857',
    groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
    name: 'OC Hospital',
    createdAt: '2022-02-22T17:51:31.791372Z',
    archivedAt: null,
  },
];

export const demoNotes = {
  'yogi': [
    {
      id: '9d09bd32-42ac-4909-941c-ff35b17a2325',
      groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
      patientId: 'yogi',
      text: "Yogi is walking, but slowly. Slowly progress. Prepare for discharge and communicate this to nephew.",
      createdById: 'bfe4a817-c763-4588-b613-838daf35bc0b',
      createdAt: '2022-02-23T17:51:32.084155Z',
      archivedAt: null,
    },
    {
      id: 'b2698adf-bbfb-4372-9131-68976ca7578c',
      groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
      patientId: 'yogi',
      text: "Administered food, explained situation to nephew. He is exploring options. Yogi looking stronger.",
      createdById: '5d594285-900a-428e-92d9-e82b15ed5597',
      createdAt: '2022-02-22T17:51:32.059606Z',
      archivedAt: null,
    },
    {
      id: 'f3f30475-cc8f-49d0-b248-2a81dc44a4e1',
      groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
      patientId: 'yogi',
      text: '42 year old bear came in with nephew. Will need food ASAP. Nephew worried - please call him and explain situation (123-456-7890).',
      createdById: '9c4ff789-17e6-474b-b194-3bc1e2d216ec',
      createdAt: '2022-02-21T17:51:32.048619Z',
      archivedAt: null,
    },
  ],
  'marvin': [
    {
      id: '4394c456-6770-4d27-8c89-774f80040c11',
      groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
      patientId: 'marvin',
      text: "30/70 O2/CO2 mix removed any symptoms. Patient looks strong. Send home with custom breathing system and train in usage.",
      createdById: '9c4ff789-17e6-474b-b194-3bc1e2d216ec',
      createdAt: '2022-02-23T17:51:32.623947Z',
      archivedAt: null,
    },
    {
      id: '5c99da3b-99ee-4517-b5bf-f74bdd7b31a9',
      groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
      patientId: 'marvin',
      text: 'Experiencing slight reaction to pure 02. Try 50/50 O2/CO2 mix. Monitor progress hourly, adjusting by increments of 10%.',
      createdById: 'bfe4a817-c763-4588-b613-838daf35bc0b',
      createdAt: '2022-02-22T17:51:32.604094Z',
      archivedAt: null,
    },
    {
      id: 'dc89e753-273d-43c5-a13f-7e1b56fcc7f8',
      groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
      patientId: 'marvin',
      text: "Young alien, unknown age. No insurance - talk to case manager. Earth atmosphere having harsh effect on lungs - nitrogen content too high. Administer pure O2.",
      createdById: '5d594285-900a-428e-92d9-e82b15ed5597',
      createdAt: '2022-02-21T17:51:32.561489Z',
      archivedAt: null,
    },
  ],
  'alvin': [
    {
      id: '2369a66a-8731-4382-a84d-d2433639969b',
      groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
      patientId: 'alvin',
      text: "Size doubled. Alvin is stable, walking. Stopped enlargement treatments. Talk to dad about next steps. Prepare for discharge.",
      createdById: '9c4ff789-17e6-474b-b194-3bc1e2d216ec',
      createdAt: '2022-02-23T17:51:31.894681Z',
      archivedAt: null,
    },
    {
      id: '7d713133-1eb9-4c91-9dd2-040582710b72',
      groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
      patientId: 'alvin',
      text: '30cc EJ, 60mins anti-shrink ray. 20% growth in 24hrs. Monitor progress closely.',
      createdById: 'bfe4a817-c763-4588-b613-838daf35bc0b',
      createdAt: '2022-02-22T17:51:31.889602Z',
      archivedAt: null,
    },
    {
      id: '65dd32d7-2d99-40a4-bcaf-985eee2aa50f',
      groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
      patientId: 'alvin',
      text: "30cc of enlargement juice, with 30mins under anti-shrink ray. No progress yet but spirit is good. Dad is concerned.",
      createdById: '9c4ff789-17e6-474b-b194-3bc1e2d216ec',
      createdAt: '2022-02-21T17:51:31.88554Z',
      archivedAt: null,
    },
    {
      id: 'b3f64378-bb87-4a8c-8457-0f6fa76f8ea0',
      groupId: 'b35f25c2-3165-4623-96f9-dfe6e5225714',
      patientId: 'alvin',
      text: "12 year old small chipmunk arrived with two brothers. All are chronically small. Be gentle, as they are fragile. Maintain communication with father (123-456-7890).",
      createdById: '5d594285-900a-428e-92d9-e82b15ed5597',
      createdAt: '2022-02-20T17:51:31.876702Z',
      archivedAt: null,
    },
  ],
};
