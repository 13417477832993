import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Row, Col, ListGroup } from 'react-bootstrap';
import { useAuth } from './AuthProvider';
import SettingsCard from './SettingsCard';
import SettingsItem from './SettingsItem';
import { Spinner } from './ui/Spinner';
import { handleError } from '../helper/error';

export type Hospital = {
  id: string;
  name: string;
};

const Hospitals: React.FC = () => {
  const [hospitals, setHospitals] = useState<Hospital[]>([]);
  const [error, setError] = useState<string>();
  const { Get, Post, Delete } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const refreshTable = useCallback(async () => {
    try {
      const res: AxiosResponse<Hospital[]> = await Get(`/api/v1/hospitals`);
      setHospitals(res.data);
      setIsLoading(false);
    } catch (err) {
      handleError(err, setError);
      setIsLoading(false);
    }
  }, [Get]);

  const saveHospital = async (name: string, setSubmitting: Function, resetForm: Function) => {
    try {
      await Post(`/api/v1/hospitals`, { name: name });
      refreshTable();
      // Formik functions.
      resetForm();
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      handleError(err, setError);
    }
  };

  const deleteHospital = async (id: string) => {
    try {
      await Delete(`/api/v1/hospitals/${id}`);
      refreshTable();
    } catch (err) {
      handleError(err, setError);
    }
  };

  useEffect(() => {
    refreshTable();
  }, [refreshTable]);

  return (
    <SettingsCard
      title="Hospitals"
      error={error}
      setError={setError}
      collapseButtonText="Add Hospital"
      collapseSave={saveHospital}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Row>
          <Col>
            <ListGroup>
              {hospitals.map((i) => {
                return (
                  <SettingsItem
                    key={i.id}
                    itemId={i.id}
                    heading={i.name}
                    deleteConfirmText="Are you sure you want to delete this hospital?"
                    deleteItem={deleteHospital}
                  />
                );
              })}
            </ListGroup>
          </Col>
        </Row>
      )}
    </SettingsCard>
  );
};

export default Hospitals;
