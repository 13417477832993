import { Formik } from 'formik';
import React, { useState, useEffect, useCallback } from 'react';
import { Form, Row, Col, ListGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuth } from './AuthProvider';
import { handleError } from '../helper/error';
import { Button } from './ui/Button';
import { FormDropdown } from './ui/FormDropdown';
import LoginFlowCard from './LoginFlowCard';
import { FormTextInput } from './ui/FormTextInput';
import { Invite } from './InviteUserModal';
import { Role } from './Roles';
import { AxiosResponse } from 'axios';
import { Invite as UnusedInvite } from './Users';
import ReactGA from 'react-ga';
import './settingsItem.css';

const OnboardingUsers: React.FC = () => {
  const { Get, Post } = useAuth();
  const [error, setError] = useState<string>();
  const [roles, setRoles] = useState<Role[]>([]);
  const [unusedInvites, setUnusedInvites] = useState<UnusedInvite[]>([]);
  const history = useHistory();

  // Google analytics tracking.
  ReactGA.pageview(window.location.pathname + window.location.search, undefined, 'Onboarding users');

  const getRoleName = (id: string) => {
    const role = roles.find((r) => r.id === id);
    return role?.name;
  };

  const refreshInvites = useCallback(async () => {
    try {
      const res: AxiosResponse<UnusedInvite[]> = await Get(`/api/v1/invites`);
      setUnusedInvites(res.data);
    } catch (err) {
      handleError(err, setError);
    }
  }, [Get]);

  useEffect(() => {
    const getRoles = async () => {
      const res: AxiosResponse<Role[]> = await Get(`/api/v1/roles`);
      setRoles(res.data);
    };

    getRoles();
    refreshInvites();
  }, [refreshInvites, Get]);

  // Schema for yup
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email address')
      .max(100, 'Email must be less than 100 characters')
      .required('Email is required'),
    roleId: Yup.string().required('Role is required'),
    isDoctor: Yup.boolean(),
  });

  return (
    <LoginFlowCard title="Invite users" error={error} setError={setError}>
      <Row>
        <Col className="text-left mb-3">
          You're almost done! Let's perform two steps to prepare your account for usage.
        </Col>
      </Row>

      <Row>
        <Col className="text-left mb-3">
          First, if there are other providers, nurse practitioners, or other staff in your group that need access to
          take or view patient notes, let's add them now by sending them email invitations.
        </Col>
      </Row>

      <Row>
        <Col className="text-left mb-4">Note that all options can be modified later in the settings.</Col>
      </Row>

      <Formik
        initialValues={{
          roleId: '',
          email: '',
          isDoctor: 0,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);

          const invite: Invite = {
            roleId: values.roleId,
            email: values.email,
            isDoctor: !!values.isDoctor, // convert this to bool for api
          };

          try {
            await Post(`/api/v1/invites`, invite);

            resetForm();
            setSubmitting(false);
            refreshInvites();

            ReactGA.event({
              category: 'User',
              action: 'Onboarding user invite',
            });
          } catch (err) {
            handleError(err, setError);
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormTextInput
              name="email"
              header="Email"
              hoverProps={{
                hoverTitle: 'Email',
                hoverText: `This is the email the user will use to sign in and receive communications from us.`,
              }}
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            />

            <FormDropdown
              name="roleId"
              header="Role"
              hoverProps={{
                hoverTitle: 'Roles',
                hoverText: `Each user is assigned a role. Administrators can perform all site actions, including managing
              users and billing.
              Staff can add patients and take notes, but cannot perform administrative actions such as
              billing or user management.`,
              }}
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            >
              <option value={''}>Role</option>
              {roles.map((r) => (
                <option key={r.id} value={r.id}>
                  {r.name}
                </option>
              ))}
            </FormDropdown>

            <FormDropdown
              name="isDoctor"
              header="Is this user a doctor?"
              hoverProps={{
                hoverTitle: 'Doctors',
                hoverText: `If a user is a doctor, patients can be assigned to them. All users can take notes, but only doctors can be assigned to patients.`,
              }}
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            >
              <option value={0}>No</option>
              <option value={1}>Yes</option>
            </FormDropdown>

            <Row className="mb-2">
              <Col>
                <Button variant="custom-primary" type="submit" spinner={isSubmitting}>
                  {unusedInvites.length > 0 ? 'Send another invitation' : 'Send invitation'}
                </Button>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  className="mx-1"
                  variant="custom-secondary"
                  onClick={() => history.push('/onboarding/hospitals')}
                >
                  {unusedInvites.length > 0 ? 'Next step' : 'Skip'}
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>

      {unusedInvites.length > 0 ? (
        <ListGroup className="mt-4">
          <Row>
            <Col className="text-left h5">Invitations Sent</Col>
          </Row>
          {unusedInvites.map((inv) => {
            return (
              <ListGroup.Item key={inv.id}>
                <Row>
                  <Col className="text-left font-weight-bold">{inv.email}</Col>
                  <Col>{getRoleName(inv.roleId)}</Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ) : null}
    </LoginFlowCard>
  );
};

export default OnboardingUsers;
