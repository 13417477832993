import React from 'react';
import { Row, Col, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { QuestionCircle } from 'react-bootstrap-icons';
import { FormikProps } from 'formik';

type HoverProps = {
  hoverTitle: string;
  hoverText: string;
  placement?: 'right' | 'top';
};

export type InputProps = Pick<FormikProps<any>, 'values' | 'handleChange' | 'handleBlur' | 'touched' | 'errors'> & {
  name: string;
  header: string;
  inputType?: 'text' | 'password';
  hoverProps?: HoverProps;
};

export const FormTextInput: React.FC<InputProps> = ({
  name,
  header,
  hoverProps,
  inputType,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
}) => {
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h4">{hoverProps?.hoverTitle}</Popover.Title>
      <Popover.Content>{hoverProps?.hoverText}</Popover.Content>
    </Popover>
  );

  return (
    <Row className="justify-content-center">
      <style type="text/css">
        {`
          .form-text-input {
            background: #fff;
            border-radius: 12px;
            height: 40px;
            
            font-family: inherit;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            text-align: left;
          }
      `}
      </style>
      <Col sm={12} md={12} lg={12}>
        <Form.Group controlId={`form${name}`}>
          <Row>
            <Col className="h6 text-left">
              {header}
              <span className="ml-1">
                {hoverProps && (
                  <OverlayTrigger
                    placement={hoverProps.placement ? hoverProps.placement : 'right'}
                    delay={{ show: 250, hide: 400 }}
                    overlay={popover}
                  >
                    <QuestionCircle />
                  </OverlayTrigger>
                )}
              </span>
            </Col>
          </Row>

          <Form.Control
            type={inputType ? inputType : 'text'}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values[name]}
            className={`form-text-input ${touched[name] && errors[name] ? 'errorFormInput' : ''}`}
          />

          <Row>
            <Col className="text-left">
              {touched[name] && errors[name] && <div className="errorMessage">{errors[name]}</div>}
            </Col>
          </Row>
        </Form.Group>
      </Col>
    </Row>
  );
};
