import { Image, Navbar, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { Button } from './ui/Button';
import logo from '../images/logo.svg';
import { useIsMobile } from './MobileProvider';
import './main.css';

export const showLandingNav = (path: string) => {
  return (
    path === '/' ||
    path === '/1' ||
    path === '/2' ||
    path.startsWith('/signup') ||
    path === '/verify' ||
    path === '/login' ||
    path === '/terms' ||
    path === '/privacy' ||
    path === '/forgot' ||
    path === '/install' ||
    path === '/contact' ||
    path.startsWith('/demo') ||
    path.startsWith('/welcome')
  );
};

const LandingNav: React.FC = () => {
  const { isMobile } = useIsMobile();
  const location = useLocation();

  const hideLogin = (path: string) => {
    return path === '/login' || path === '/verify' || path.startsWith('/signup/finish') || path.startsWith('/welcome');
  };

  const hideSignup = (path: string) => {
    return path.startsWith('/signup') || path === '/verify' || path.startsWith('/welcome');
  };

  const hideDemo = (path: string) => {
    return path !== '/' && path !== '/1' && path !== '/2';
  };

  if (showLandingNav(location.pathname)) {
    return (
      <Navbar bg="#fdfdfd" sticky="top" style={{ backgroundColor: '#fdfdfd' }}>
        <Nav.Link as={Link} to={`/`} style={{ padding: 0 }}>
          <Navbar.Brand style={isMobile() ? { marginRight: 0 } : {}}>
            <Image style={{ height: 80, minHeight: 80, width: '100%' }} src={logo} fluid />
          </Navbar.Brand>
        </Nav.Link>

        {location.pathname.startsWith('/demo') && !isMobile() && (
          <Navbar.Text style={{ fontSize: '1.5rem', marginTop: '15px' }}>
            <b>
              <em>Demo</em>
            </b>
          </Navbar.Text>
        )}

        <Nav className="ml-auto">
          {!hideDemo(location.pathname) && !isMobile() ? (
            <Nav.Link as={Link} to={`/demo/yogi`} style={{ marginTop: isMobile() ? 5 : 10 }}>
              <Button variant="custom-secondary" style={{ fontSize: isMobile() ? 12 : 20, height: 50 }}>
                Try It Out
              </Button>
            </Nav.Link>
          ) : null}

          {!hideLogin(location.pathname) ? (
            <Nav.Link as={Link} to={`/login`} style={{ marginTop: isMobile() ? 5 : 10 }}>
              <Button variant="custom-secondary" style={{ fontSize: isMobile() ? 12 : 20, height: 50 }}>
                Log In
              </Button>
            </Nav.Link>
          ) : null}

          {!hideSignup(location.pathname) ? (
            <Nav.Link as={Link} to={`/signup`} style={{ marginTop: isMobile() ? 5 : 10 }}>
              <Button style={{ fontSize: isMobile() ? 12 : 20, height: 50 }}>Sign Up</Button>
            </Nav.Link>
          ) : null}
        </Nav>
      </Navbar>
    );
  } else {
    return null;
  }
};

export default LandingNav;
