import React, { useState } from 'react';
import { Row, Col, Collapse } from 'react-bootstrap';
import { Patient } from './Main';
import { Button } from './ui/Button';
import NewNote from './NewNote';
import { Icon } from './ui/Icon';
import DeleteConfirmModal from './DeleteConfirmModal';
import './patientHeader.css';

type Props = {
  patient: Patient;
  hospitalName?: string;
  doctorName?: string;
  diagnosisName?: string;
  userId: string;
  refreshTable: Function;
  deletePatient: Function;
  createNote: Function;
  handleEditPatient: Function;
};

const PatientItem: React.FC<Props> = ({
  patient,
  hospitalName,
  doctorName,
  diagnosisName,
  userId,
  refreshTable,
  deletePatient,
  createNote,
  handleEditPatient,
}) => {
  const [openNoteInput, setOpenNoteInput] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  return (
    <div className="mt-3 mb-2">
      <Row>
        <Col sm={8}>
          <Row>
            <Col className="patientHeaderName">
              {patient.lastName}, {patient.firstName} <Icon icon="pencil" onClick={() => handleEditPatient(patient)} />{' '}
              <Icon icon="trash" onClick={() => setShowDeleteModal(true)} />
            </Col>
          </Row>

          <Row>
            <Col className="patientSubHeader">
              <Row>
                <Col>
                  Dr. {doctorName}, {hospitalName}, {patient.floor}
                </Col>
              </Row>

              <Row>
                <Col>{diagnosisName}</Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col sm={4} className="my-auto py-2">
          <Button
            onClick={() => setOpenNoteInput(!openNoteInput)}
            aria-controls="collapse-new-note"
            aria-expanded={openNoteInput}
          >
            New Note
          </Button>
        </Col>
      </Row>

      <Row>
        <Col className="mb-2">
          <Collapse in={openNoteInput}>
            <div id="collapse-new-note">
              <NewNote
                patientId={patient.id!}
                createNote={createNote}
                userId={userId}
                refreshTable={refreshTable}
                setOpenNoteInput={setOpenNoteInput}
              />
            </div>
          </Collapse>
        </Col>
      </Row>

      <DeleteConfirmModal
        showModal={showDeleteModal}
        text="Are you sure you want to delete this patient?"
        deleteFunction={() => deletePatient(patient.id)}
        setShowModal={setShowDeleteModal}
      />
    </div>
  );
};

export default PatientItem;
