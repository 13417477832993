import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Note } from './PatientNotes';
import DeleteConfirmModal from './DeleteConfirmModal';
import { Icon } from './ui/Icon';
import { useIsMobile } from './MobileProvider';
import './noteItem.css';

type Props = {
  note: Note;
  createdBy?: string;
  deleteNote: Function;
};

const NoteItem: React.FC<Props> = ({ note, createdBy, deleteNote }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { isMobile } = useIsMobile();

  const getDate = (d?: string) => {
    var date: Date;
    if (!d) {
      date = new Date();
    } else {
      date = new Date(d);
    }

    if (isMobile()) {
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      });
    } else {
      return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      });
    }
  };

  return (
    <div>
      <Row className="noteItemHeader mb-2">
        <Col className="text-left font-weight-bold my-auto">
          {createdBy}, {getDate(note.createdAt)}
        </Col>
        <Col className="text-right my-auto">
          <Icon icon="trash" onClick={() => setShowDeleteModal(true)} />
        </Col>
      </Row>
      <Row>
        <Col className="text-left">{note.text}</Col>
      </Row>
      <DeleteConfirmModal
        showModal={showDeleteModal}
        text="Are you sure you want to delete this note?"
        deleteFunction={() => deleteNote(note.id)}
        setShowModal={setShowDeleteModal}
      />
    </div>
  );
};

export default NoteItem;
