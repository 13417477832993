import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoginFlowCard from './LoginFlowCard';
import androidSettings from '../images/icons/android-settings.png';
import iosShare from '../images/icons/ios-share.png';
import icon from '../images/mainLogo.png';
import ReactGA from 'react-ga';
import './login.css';

const HomeScreenInstall: React.FC = () => {
  // Google analytics tracking.
  ReactGA.pageview(window.location.pathname + window.location.search, undefined, 'Install');

  return (
    <LoginFlowCard title="Install a shortcut on the Home Screen!">
      <Row>
        <Col className="h4 text-left">iPhone</Col>
      </Row>

      <Row className="mb-3 mt-2">
        <Col className="text-left">
          1. Open <b>Safari</b> and navigate to the Roundnotes login page.
        </Col>
      </Row>

      <Row className="my-3">
        <Col className="text-left">
          2. Tap the share icon <Image src={iosShare} width="20px" /> in the bottom bar.
        </Col>
      </Row>

      <Row className="my-3">
        <Col className="text-left">3. Scroll down and select "Add to Home Screen".</Col>
      </Row>

      <Row className="my-3">
        <Col className="text-left">4. Click "Add" when prompted on the next popup.</Col>
      </Row>

      <Row className="my-3">
        <Col className="text-left">
          5. Done! Tap the icon <Image src={icon} width="20px" /> on your Home Screen to open Roundnotes.
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="h4 text-left">Android</Col>
      </Row>

      <Row className="mb-3 mt-2">
        <Col className="text-left">
          1. Open <b>Chrome</b> and navigate to the Roundnotes login page.
        </Col>
      </Row>

      <Row className="my-3">
        <Col className="text-left">
          2. Tap the settings menu <Image src={androidSettings} width="20px" /> in the top right.
        </Col>
      </Row>

      <Row className="my-3">
        <Col className="text-left">3. Select "Add to Home Screen".</Col>
      </Row>

      <Row className="my-3">
        <Col className="text-left">4. Click "Add" when prompted on the next popup.</Col>
      </Row>

      <Row className="my-3">
        <Col className="text-left">
          5. Done! Tap the icon <Image src={icon} width="20px" /> on your Home Screen to open Roundnotes.
        </Col>
      </Row>

      <Row className="mt-5">
        <Col>
          <Link to={`/login`}>Return to login</Link>
        </Col>
      </Row>
    </LoginFlowCard>
  );
};

export default HomeScreenInstall;
