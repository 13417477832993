import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Dropdown } from 'react-bootstrap';
import { Button } from './ui/Button';
import { Patient } from './Main';
import { User } from './Users';
import { Hospital } from './Hospitals';
import { XCircle } from 'react-bootstrap-icons';

type Props = {
  patients: Patient[];
  filteredPatients: Patient[];
  doctors: User[];
  hospitals: Hospital[];
  setFilteredPatients: Function;
  setShowPatientModal: Function;
};

const PatientFilters: React.FC<Props> = ({
  patients,
  doctors,
  hospitals,
  setFilteredPatients,
  setShowPatientModal,
}) => {
  // TODO: Make the filters properly intersect - don't force their independence.
  const [doctorFilter, setDoctorFilter] = useState<User | undefined>(undefined);
  const [hospitalFilter, setHospitalFilter] = useState<Hospital | undefined>(undefined);

  const clearFilters = () => {
    setHospitalFilter(undefined);
    setDoctorFilter(undefined);
    setFilteredPatients(patients);
  };

  useEffect(() => {
    const filteredPatients = patients.filter((p) => {
      let matchesDoctor = true;
      if (doctorFilter) {
        matchesDoctor = p.doctorId === doctorFilter.id;
      }

      let matchesHospital = true;
      if (hospitalFilter) {
        matchesHospital = p.hospitalId === hospitalFilter.id;
      }

      return matchesDoctor && matchesHospital;
    });

    setFilteredPatients(filteredPatients);
  }, [doctorFilter, hospitalFilter, setFilteredPatients, patients]);

  // Shortens the text of the selected filter if necessary.
  const shortenFilterText = (fullText: string) => {
    const shortenLength = 8;
    if (fullText.length > shortenLength) {
      return `${fullText.substring(0, shortenLength)}...`;
    }
    return fullText;
  };

  return (
    <>
      <style type="text/css">
        {`
          .btn-filter-primary {
            height: 30px;

            background: #F7F7F7;
            border-radius: 100px;

            font-family: Helvetica Neue;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 15px;
          }

          .btn-filter-primary:hover {
            background: #3A2B35;
            color: #FFF;
          }

          .btn-filter-primary:disabled {
            background: #F7F7F7;
            color: #6c6c6c;
          }

          .btn-filter-primary::after {
            float: right;
            margin-top: 6px;
          }
      `}
      </style>
      <Container fluid className="px-0 my-3">
        <Row className="mb-3">
          <Col>
            <Button block variant="custom-primary" onClick={() => setShowPatientModal(true)}>
              New Patient
            </Button>
          </Col>
        </Row>

        <Row noGutters>
          <Col xs={5}>
            <Dropdown>
              <Dropdown.Toggle block variant="filter-primary">
                {doctorFilter ? shortenFilterText(doctorFilter.lastName) : 'Doctor'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {doctors.map((d) => (
                  <Dropdown.Item key={d.id} value={d.id} onClick={() => setDoctorFilter(d)}>
                    {d.lastName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col xs={5}>
            <Dropdown>
              <Dropdown.Toggle block variant="filter-primary">
                {hospitalFilter ? shortenFilterText(hospitalFilter.name) : 'Hospital'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {hospitals.map((h) => (
                  <Dropdown.Item key={h.id} value={h.id} onClick={() => setHospitalFilter(h)}>
                    {h.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>

          <Col xs={2}>
            <Button block variant="filter-primary" disabled={!doctorFilter && !hospitalFilter} onClick={clearFilters}>
              <XCircle />
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PatientFilters;
