import React, { useEffect } from 'react';
import { Col, Row, Alert } from 'react-bootstrap';
import { ExclamationCircle } from 'react-bootstrap-icons';
import { useIsMobile } from './MobileProvider';

const DISPLAY_TIME = 5000; // How long the error will be displayed.

export type ErrorProps = {
  error: string;
  setError: Function;
  sm?: number;
  lg?: number;
};

const DisplayError: React.FC<ErrorProps> = ({ error, setError, sm, lg }) => {
  const { isMobile } = useIsMobile();

  useEffect(() => {
    setTimeout(() => {
      setError(undefined);
    }, DISPLAY_TIME);
  }, [setError]);

  return (
    <Row noGutters className="justify-content-center">
      <Col sm={sm ? sm : 12} lg={lg ? lg : 12}>
        <Alert variant="danger">
          <Row className="justify-content-center errorRow">
            {!isMobile() ? (
              <div className="mr-1">
                <ExclamationCircle size={20} />
              </div>
            ) : null}

            <div className="ml-1">{error}</div>
          </Row>
        </Alert>
      </Col>
    </Row>
  );
};

export default DisplayError;
