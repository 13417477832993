import React, { useEffect } from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import { User } from './Users';
import { Patient, getHospitalName, getUserLastName } from './Main';
import { Hospital } from './Hospitals';
import NoteItem from './NoteItem';
import PatientHeader from './PatientHeader';
import { useIsMobile } from './MobileProvider';

export type Note = {
  id: string;
  patientId: string;
  text: string;
  createdById: string;
  createdAt?: string;
};

type Props = {
  patient: Patient;
  notes: Note[];
  users: User[];
  hospitals: Hospital[];
  refreshTable: Function;
  deletePatient: Function;
  createNote: Function;
  deleteNote: Function;
  handleEditPatient: Function;
  userId: string;
  setError: (value: React.SetStateAction<string | undefined>) => void;
};

const PatientNotes: React.FC<Props> = ({
  patient,
  notes,
  users,
  hospitals,
  refreshTable,
  deletePatient,
  createNote,
  deleteNote,
  handleEditPatient,
  userId,
  setError,
}) => {
  const { isMobile } = useIsMobile();

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, [patient]);

  return (
    <div className={!isMobile() ? 'px-3' : ''}>
      <PatientHeader
        patient={patient}
        hospitalName={getHospitalName(patient.hospitalId, hospitals)}
        doctorName={getUserLastName(patient.doctorId, users)}
        diagnosisName={patient.diagnosis}
        refreshTable={refreshTable}
        deletePatient={deletePatient}
        createNote={createNote}
        handleEditPatient={handleEditPatient}
        userId={userId}
      />
      {notes.length > 0 ? (
        <ListGroup>
          {notes.map((note) => {
            return (
              <ListGroup.Item style={{ padding: isMobile() ? '0.75rem 1.0rem' : '0.75rem 1.25rem' }} key={note.id}>
                <NoteItem note={note} createdBy={getUserLastName(note.createdById, users)} deleteNote={deleteNote} />
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      ) : (
        <Container className="my-3">No notes for this patient</Container>
      )}
    </div>
  );
};

export default PatientNotes;
