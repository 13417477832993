import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoginFlowCard from './LoginFlowCard';
import ReactGA from 'react-ga';

const Verify: React.FC = () => {
  // Google analytics tracking.
  ReactGA.pageview(window.location.pathname + window.location.search, undefined, 'Verify');

  return (
    <LoginFlowCard title="Please verify your email.">
      <Row>
        <Col className="text-left pb-3">
          We sent you an email containing a link with instructions about how to complete sign up. If you did not receive
          an email, please check your spam folder or try again.
        </Col>
      </Row>
      <Row className="pt-1">
        <Col>
          <Link to={`/`}>Back</Link>
        </Col>
      </Row>
    </LoginFlowCard>
  );
};

export default Verify;
