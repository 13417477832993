import React from 'react';
import { Container, Modal, Row, Col } from 'react-bootstrap';
import { Button } from './ui/Button';
import { useIsMobile } from './MobileProvider';

type Props = {
  text: string;
  showModal: boolean;
  deleteFunction: Function;
  setShowModal: Function;
};

const DeleteConfirmModal: React.FC<Props> = ({ showModal, text, deleteFunction, setShowModal }) => {
  const { isMobile } = useIsMobile();
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} animation={false} centered={isMobile()}>
      <Modal.Body>
        <Container className="text-center">
          <Row>
            <Col className="my-1">{text}</Col>
          </Row>
          <Row>
            <Col>
              <Button
                className="my-2 mx-1"
                onClick={() => {
                  deleteFunction();
                  setShowModal(false);
                }}
              >
                Delete
              </Button>
              <Button className="my-2 mx-1" variant="custom-secondary" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteConfirmModal;
