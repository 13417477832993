import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import './login.css';
import { handleError } from '../helper/error';
import { Button } from './ui/Button';
import LoginFlowCard from './LoginFlowCard';
import { FormTextInput } from './ui/FormTextInput';
import { useAuth } from './AuthProvider';
import ReactGA from 'react-ga';

type UrlParams = {
  inviteId: string;
};

type Registration = {
  inviteId: string;
  firstName: string;
  lastName: string;
  password: string;
};

const Register: React.FC = () => {
  const [error, setError] = useState<string>();
  const { inviteId } = useParams<UrlParams>();
  const history = useHistory();
  const auth = useAuth();

  // Google analytics tracking.
  ReactGA.pageview(window.location.pathname + window.location.search, undefined, 'Invited user sign up');

  // Schema for yup
  const validationSchema = Yup.object().shape({
    lastName: Yup.string()
      .min(2, 'Last name must have at least 2 characters')
      .max(50, 'Last name cannot be longer than 50 characters')
      .required('Last name is required'),
    firstName: Yup.string()
      .min(2, 'First name must have at least 2 characters')
      .max(50, 'First name cannot be longer than 50 characters')
      .required('First name is required'),
    password: Yup.string().min(8, 'Password must have at least 8 characters').required('Password is required'),
    confirm: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Password confirmation is required'),
  });

  return (
    <LoginFlowCard title="Sign up" error={error} setError={setError}>
      <>
        <Row>
          <Col className="text-left mb-4">
            You've been invited to use Roundnotes! We just need a little more information before you start.
          </Col>
        </Row>

        <Formik
          initialValues={{
            lastName: '',
            firstName: '',
            password: '',
            confirm: '',
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            // When button submits form and form is in the process of submitting, submit button is disabled
            setSubmitting(true);

            const registration: Registration = {
              inviteId: inviteId,
              firstName: values.firstName,
              lastName: values.lastName,
              password: values.password,
            };

            try {
              await auth.Post(`/api/register`, registration);

              ReactGA.event({
                category: 'Invited User',
                action: 'Invited sign up',
              });

              resetForm();
              setSubmitting(false);
              history.push('/notes');
            } catch (err) {
              setSubmitting(false);
              handleError(err, setError);
            }
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <FormTextInput
                name="firstName"
                header="First name"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              />

              <FormTextInput
                name="lastName"
                header="Last name"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              />

              <FormTextInput
                name="password"
                inputType="password"
                header="Password"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              />

              <FormTextInput
                name="confirm"
                inputType="password"
                header="Confirm password"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              />

              <Row className="mt-2">
                <Col>
                  <Button style={{ fontSize: 20, height: 50 }} type="submit" spinner={isSubmitting}>
                    Create account
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </>
    </LoginFlowCard>
  );
};

export default Register;
