import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './footer.css';

const Footer: React.FC = () => {
  return (
    <Container className="my-4" style={{ color: 'rgba(0,0,0,.5)' }}>
      <Row className="justify-content-center">
        <Col>© Roundnotes Inc. {new Date().getFullYear()}</Col>
      </Row>

      <Row className="links justify-content-center">
        <Col className="text-center">
          <Link to={`/terms`}>Terms and Conditions</Link>{' '}
          <Link className="ml-3" to={`/privacy`}>
            Privacy Policy
          </Link>
        </Col>
      </Row>

      <Row className="links justify-content-center">
        <Col className="text-center">
          <Link to={`/contact`}>Contact Us</Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
