import React from 'react';
import { Row, Col, Container, ListGroup } from 'react-bootstrap';
import { Patient } from './Main';
import { Link } from 'react-router-dom';
import { useIsMobile } from './MobileProvider';
import './patientItem.css';

type Props = {
  patient: Patient;
  doctorName?: string;
  hospitalName?: string;
  currentPatient: string | undefined;
  linkTo: string;
  onClick: Function;
};

const PatientItem: React.FC<Props> = ({ patient, doctorName, hospitalName, currentPatient, linkTo, onClick }) => {
  const { isMobile } = useIsMobile();

  return (
    <ListGroup.Item
      className={`${currentPatient === patient.id && !isMobile() ? 'patientActive' : ''}`}
      action
      key={patient.id}
      as={Link}
      to={linkTo}
      onClick={() => onClick()}
    >
      <Container className="patientItemContainer">
        <Row className="patientItemHeader">
          <Col>
            {patient.lastName}, {patient.firstName}
          </Col>
        </Row>
        <Row className="patientItemSubHeader">
          <Col>
            {doctorName} - {hospitalName} - {patient.floor}
          </Col>
        </Row>
      </Container>
    </ListGroup.Item>
  );
};

export default PatientItem;
