import React from 'react';
import { Pencil, Trash } from 'react-bootstrap-icons';
import './icon.css';

export type Props = {
  icon: 'trash' | 'pencil';
  onClick: Function;
};

export const Icon: React.FC<Props> = ({ icon, onClick }) => {
  if (icon === 'pencil') {
    return <Pencil className="icon" onClick={() => onClick()} size={15} color="#ff15b0" />;
  } else if (icon === 'trash') {
    return <Trash className="icon" onClick={() => onClick()} size={15} color="#ff15b0" />;
  } else {
    return null;
  }
};
