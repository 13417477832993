import React from 'react';
import { useAuth } from './AuthProvider';
import { Route, Redirect, RouteProps, useLocation } from 'react-router-dom';

const AuthRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const { user, tokenIsValid } = useAuth();
  const location = useLocation();

  // Only allow access to billing page if group status is invalid.
  if (user?.groupStatus === 'NeedsPayment' && location.pathname !== '/settings/billing') {
    return (
      <Redirect
        to={{
          pathname: '/settings/billing',
          state: { from: location },
        }}
      />
    );
  }

  return (
    <Route
      {...props}
      render={({ location }) =>
        user && tokenIsValid() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/logout',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default AuthRoute;
