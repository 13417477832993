import { Formik } from 'formik';
import React, { useState } from 'react';
import { Form, Row, Col, Alert } from 'react-bootstrap';
import { useParams, useHistory, Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuth } from './AuthProvider';
import { handleError } from '../helper/error';
import { Button } from './ui/Button';
import LoginFlowCard from './LoginFlowCard';
import { FormTextInput } from './ui/FormTextInput';
import { useIsMobile } from './MobileProvider';
import baa from '../legal/baa.pdf';
import './login.css';
import './signupFinish.css';
import ReactGA from 'react-ga';

type UrlParams = {
  signupId: string;
};

const Verify: React.FC = () => {
  const auth = useAuth();
  const { signupId } = useParams<UrlParams>();
  const [err, setError] = useState<string | undefined>(undefined);
  const history = useHistory();
  const { isMobile } = useIsMobile();

  // Google analytics tracking.
  ReactGA.pageview(window.location.pathname + window.location.search, undefined, 'Finish sign up');

  // If this was a referral, the referring groups's id will be stored here.
  const referralParam = localStorage.getItem('referralGroupId');

  // Schema for yup
  const validationSchema = Yup.object().shape({
    groupName: Yup.string()
      .min(2, 'Organization name must have at least 2 characters')
      .required('Organization name is required'),
    agreeBaa: Yup.boolean().oneOf([true], 'You must accept and sign the Business Associate Agreement'),
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Password confirmation is required'),
  });

  return (
    <LoginFlowCard title="Complete your sign up" error={err} setError={setError}>
      <Row>
        <Col className="text-left mb-4">Your first month of usage will be free, and you can cancel at any time.</Col>
      </Row>

      <Formik
        initialValues={{
          groupName: '',
          password: '',
          confirmPassword: '',
          agreeBaa: false,
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);

          try {
            await auth.Post('/api/signup/finish', {
              signupId,
              groupName: values.groupName,
              password: values.password,
              ...(referralParam && { referralGroupId: referralParam }),
            });

            ReactGA.event({
              category: 'Landing',
              action: 'Finish sign up',
            });

            setSubmitting(false);
            history.push('/onboarding/users');
          } catch (err) {
            handleError(err, setError);
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <FormTextInput
              name="password"
              inputType="password"
              header="Password"
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            />

            <FormTextInput
              name="confirmPassword"
              inputType="password"
              header="Confirm password"
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
            />

            <FormTextInput
              name="groupName"
              header="Organization name"
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              hoverProps={{
                hoverTitle: 'Organization',
                hoverText:
                  'This is the name of your organization, as it will show up in the business associate agreement.',
                placement: isMobile() ? 'top' : 'right',
              }}
            />

            <div className="mt-2 mb-4">
              <Row className="text-left h6">
                <Col>Business Associate Agreement (BAA)</Col>
              </Row>

              <Row className="text-left">
                <Col>
                  This agreement allows your organization and Roundnotes to maintain HIPAA compliance. You can view your
                  signed copy at any time in the Account tab in the Settings.
                </Col>
              </Row>

              <Row className="mt-2">
                <Col>
                  <Alert variant="secondary">
                    <Form.Check type="checkbox" className={`text-left`}>
                      <Form.Check.Input name="agreeBaa" id="agreeBaa" onChange={handleChange} onBlur={handleBlur} />
                      <Form.Check.Label>Accept and sign Business Associate Agreement</Form.Check.Label>
                    </Form.Check>

                    {touched.agreeBaa && errors.agreeBaa && (
                      <Row style={{ color: 'red', fontSize: 13 }}>
                        <Col className="text-left">{errors.agreeBaa}</Col>
                      </Row>
                    )}

                    <Row>
                      <Col className="text-left">
                        <Link
                          to={baa}
                          target="_blank"
                          onClick={() => {
                            ReactGA.event({
                              category: 'Landing',
                              action: 'View BAA',
                            });
                          }}
                        >
                          View Business Associate Agreement
                        </Link>
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
            </div>

            <hr className="mb-2" />

            <Row className="justify-content-center mt-3">
              <Col sm={5} lg={5}>
                <Button style={{ fontSize: 20, height: 50 }} block type="submit" spinner={isSubmitting}>
                  Finish
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </LoginFlowCard>
  );
};

export default Verify;
