import React from 'react';
import { useAuth } from './AuthProvider';
import { Route, Redirect, RouteProps } from 'react-router-dom';

// UnauthRoute disallows access to a route when user is logged in. For example, /login.
const UnauthRoute: React.FC<RouteProps> = ({ children, ...props }) => {
  const { user, tokenIsValid } = useAuth();

  return (
    <Route
      {...props}
      render={({ location }) =>
        !user || !tokenIsValid() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/notes',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default UnauthRoute;
