import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { handleError } from '../helper/error';
import { Button } from './ui/Button';
import { FormTextInput } from './ui/FormTextInput';
import LoginFlowCard from './LoginFlowCard';
import { useIsMobile } from './MobileProvider';
import './login.css';

const ForgotPassword: React.FC = () => {
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<boolean>(false);
  const [cardTitle, setCardTitle] = useState<string>('Reset your password');
  const { isMobile } = useIsMobile();

  // Schema for yup
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Must be a valid email address').required('Email is required'),
  });

  return (
    <LoginFlowCard title={cardTitle} error={error} setError={setError}>
      {success ? (
        <Row>
          <Col className="text-left pb-3">
            If you did not receive an email, please check your spam folder or try again.
          </Col>
        </Row>
      ) : (
        <>
          <Formik
            validationSchema={validationSchema}
            initialValues={{ email: '' }}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);

              try {
                await axios.post(`/api/send-password-reset`, {
                  email: values.email,
                });
                resetForm();
                setSubmitting(false);
                setSuccess(true);
                setCardTitle('Check your email for password reset instructions.');
              } catch (err) {
                handleError(err, setError);
                setSubmitting(false);
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormTextInput
                  name="email"
                  header="Email"
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />

                <Row className="justify-content-center">
                  <Col xs={12} sm={10} md={8} lg={6}>
                    <Button type="submit" spinner={isSubmitting} style={{ fontSize: isMobile() ? 12 : 20, height: 50 }}>
                      Continue
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </>
      )}

      <Row className="pt-1">
        <Col>
          <Link to={`/login`}>Return to login</Link>
        </Col>
      </Row>
    </LoginFlowCard>
  );
};

export default ForgotPassword;
