import React, { useState } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Formik } from 'formik';
import { useParams, Link } from 'react-router-dom';
import * as Yup from 'yup';
import './login.css';
import { handleError } from '../helper/error';
import { FormTextInput } from './ui/FormTextInput';
import LoginFlowCard from './LoginFlowCard';
import { Button } from './ui/Button';

type UrlParams = {
  resetId: string;
};

const ResetPassword: React.FC = () => {
  const [error, setError] = useState<string>();
  const [success, setSuccess] = useState<boolean>(false);
  const { resetId } = useParams<UrlParams>();
  const [cardTitle, setCardTitle] = useState<string>('Reset your password');

  // Schema for yup
  const validationSchema = Yup.object().shape({
    password: Yup.string().min(8, 'Password must have at least 8 characters').required('Password is required'),
    confirm: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  return (
    <LoginFlowCard title={cardTitle} error={error} setError={setError}>
      {success ? (
        <>
          <Row>
            <Col className="text-left pb-3">Please log in using your new password.</Col>
          </Row>

          <Row className="pt-1">
            <Col>
              <Link to={`/login`}>Return to login</Link>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Formik
            initialValues={{ password: '', confirm: '' }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
              // When button submits form and form is in the process of submitting, submit button is disabled
              setSubmitting(true);

              try {
                await axios.post(`/api/reset-password`, {
                  resetId: resetId,
                  password: values.password,
                });
                resetForm();
                setSubmitting(false);
                setSuccess(true);
                setCardTitle('Your password was successfully reset.');
              } catch (err) {
                setSubmitting(false);
                handleError(err, setError);
              }
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <FormTextInput
                  name="password"
                  header="New password"
                  inputType="password"
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />

                <FormTextInput
                  name="confirm"
                  header="Confirm password"
                  inputType="password"
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  touched={touched}
                  errors={errors}
                />

                <Row className="justify-content-md-center">
                  <Col xs={12} sm={10} md={8} lg={6}>
                    <Button type="submit">Reset</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </>
      )}
    </LoginFlowCard>
  );
};

export default ResetPassword;
