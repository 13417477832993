import React from 'react';
import 'babel-polyfill';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { AuthProvider } from './components/AuthProvider';
import { MobileProvider } from './components/MobileProvider';
import TopNav from './components/TopNav';
import Footer from './components/Footer';
import LandingNav from './components/LandingNav';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Settings from './components/Settings';
import AuthRoute from './components/AuthRoute';
import UnauthRoute from './components/UnauthRoute';
import Signup from './components/Signup';
import Verify from './components/Verify';
import Privacy from './components/Privacy';
import Terms from './components/Terms';
import Landing from './components/Landing';
import SignupFinish from './components/SignupFinish';
import OnboardingUsers from './components/OnboardingUsers';
import OnboardingHospitals from './components/OnboardingHospitals';
import Main from './components/Main';
import Register from './components/Register';
import Logout from './components/Logout';
import Login from './components/Login';
import HomeScreenInstall from './components/HomeScreenInstall';
import Contact from './components/Contact';
import ReactGA from 'react-ga';

function App() {
  ReactGA.initialize('UA-217781481-3');

  return (
    <div className="App">
      <Router>
        <MobileProvider>
          <AuthProvider>
            <TopNav />
            <LandingNav />

            <Switch>
              <Route exact path="/">
                <Landing />
              </Route>
              <Route exact path="/1">
                <Landing
                  header="Elevate the patient experience with electronic rounding notes."
                  subheader={`Roundnotes is a mobile-friendly website for collaborative, HIPAA-compliant, 
                  electronic rounding notes. It's easier than keeping paper notes and elevates your patient 
                  experience by creating seamless communication between providers regarding patient care, progress, 
                  and preferences.`}
                />
              </Route>
              <Route exact path="/2">
                <Landing
                  header="Make your life easier with paperless rounding notes."
                  subheader={`Roundnotes is a mobile-friendly website for collaborative, HIPAA-compliant, 
                  electronic rounding notes. It's easier than keeping paper notes and elevates your patient 
                  experience by creating seamless communication between providers regarding patient care, progress, 
                  and preferences.`}
                />
              </Route>
              <AuthRoute path="/notes/:patientId?">
                <Main />
              </AuthRoute>
              <AuthRoute path="/settings/:page?">
                <Settings />
              </AuthRoute>
              <AuthRoute path="/onboarding/users">
                <OnboardingUsers />
              </AuthRoute>
              <AuthRoute path="/onboarding/hospitals">
                <OnboardingHospitals />
              </AuthRoute>
              <UnauthRoute path="/login">
                <Login />
              </UnauthRoute>
              <Route path="/logout">
                <Logout />
              </Route>
              <UnauthRoute path="/forgot">
                <ForgotPassword />
              </UnauthRoute>
              <UnauthRoute path="/reset/:resetId">
                <ResetPassword />
              </UnauthRoute>
              <UnauthRoute path="/welcome/:inviteId">
                <Register />
              </UnauthRoute>
              <UnauthRoute path="/signup/finish/:signupId">
                <SignupFinish />
              </UnauthRoute>
              <UnauthRoute path="/signup">
                <Signup />
              </UnauthRoute>
              <UnauthRoute path="/verify">
                <Verify />
              </UnauthRoute>
              <Route path="/demo/:patientId?">
                <Main />
              </Route>
              <Route path="/install">
                <HomeScreenInstall />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/privacy">
                <Privacy />
              </Route>
              <Route path="/terms">
                <Terms />
              </Route>
              {/* Redirect all other paths to notes */}
              <Redirect from="*" to="/notes" />
            </Switch>

            <Footer />
          </AuthProvider>
        </MobileProvider>
      </Router>
    </div>
  );
}

export default App;
