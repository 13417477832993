import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const Logout: React.FC = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.logout();
  });

  return <Redirect to="/login" />;
};

export default Logout;
