import React, { useState } from 'react';
import { Col, Form, Modal, Row, Dropdown, Alert } from 'react-bootstrap';
import { Formik } from 'formik';
import { Hospital } from './Hospitals';
import { User } from './Users';
import { Patient } from './Main';
import * as Yup from 'yup';
import './modal.css';
import DisplayError from './DisplayError';
import { Button } from './ui/Button';
import { useIsMobile } from './MobileProvider';
import { handleError } from '../helper/error';

type Props = {
  showModal: boolean;
  setShowModal: Function;
  refreshTable: Function;
  editPatient?: Patient;
  setEditPatient: Function;
  createOrUpdatePatient: Function;
  doctors: User[];
  hospitals: Hospital[];
};

const PatientModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  refreshTable,
  editPatient,
  setEditPatient,
  createOrUpdatePatient,
  doctors,
  hospitals,
}) => {
  const [error, setError] = useState<string>();
  const { isMobile } = useIsMobile();

  const onClose = () => {
    // If we were editing a patient, clear patient from state.
    if (editPatient) {
      setEditPatient(undefined);
    }
    setShowModal(false);
  };

  // Schema for yup
  const validationSchema = Yup.object().shape({
    lastName: Yup.string()
      .min(2, 'Last name is too short')
      .max(50, 'Last name is too long')
      .required('Last name is required'),
    firstName: Yup.string()
      .min(2, 'First name is too short')
      .max(50, 'First name is too long')
      .required('First name is required'),
    hospitalId: Yup.string().min(2, 'Hospital is required').required('Hospital is required'),
    floor: Yup.string(),
    doctorId: Yup.string().min(2, 'Doctor is required').required('Doctor is required'),
    diagnosis: Yup.string().min(2, 'Diagnosis is too short').max(50, 'Diagnosis is too long'),
  });

  const findHospitalName = (hospitalId: string): string => {
    const hospital = hospitals.find((h) => h.id === hospitalId);
    return hospital?.name || '';
  };

  const findDoctorName = (doctorId: string): string => {
    const doctor = doctors.find((d) => d.id === doctorId);
    return doctor?.lastName || '';
  };

  return (
    <Modal show={showModal} onHide={() => onClose()} animation={false} centered={isMobile()}>
      <style type="text/css">
        {`
          .btn-form-primary {
            background: #FFF;
            color: #495057;
            border-radius: 12px;
            height: 40px;
            border: 1px solid #ced4da;

            font-family: Helvetica Neue;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            text-align: left;
          }
      `}
      </style>
      <Formik
        initialValues={{
          lastName: editPatient?.lastName ?? '',
          firstName: editPatient?.firstName ?? '',
          hospitalId: editPatient?.hospitalId ?? '',
          floor: editPatient?.floor ?? '',
          doctorId: editPatient?.doctorId ?? '',
          diagnosis: editPatient?.diagnosis ?? '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);

          const patient: Patient = {
            id: editPatient?.id ?? undefined,
            lastName: values.lastName,
            firstName: values.firstName,
            hospitalId: values.hospitalId,
            floor: values.floor,
            doctorId: values.doctorId,
            diagnosis: values.diagnosis,
          };

          try {
            await createOrUpdatePatient(editPatient, patient);

            resetForm();
            setSubmitting(false);
            refreshTable();
            setEditPatient(undefined);
            setShowModal(false);
          } catch (err) {
            handleError(err, setError);
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{editPatient ? 'Edit' : 'New'} Patient</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {error && <DisplayError error={error} setError={setError}></DisplayError>}

              {doctors.length === 0 && (
                <Row>
                  <Col>
                    <Alert variant="danger" className="text-left">
                      Creating a patient requires selecting a doctor. Please add or modify a user such that there is at
                      least one doctor in the Users section in the Settings.
                    </Alert>
                  </Col>
                </Row>
              )}

              {hospitals.length === 0 && (
                <Row>
                  <Col>
                    <Alert variant="danger" className="text-left">
                      Creating a patient requires selecting a hospital. Please add one in the Hospitals section in the
                      Settings.
                    </Alert>
                  </Col>
                </Row>
              )}

              <Row noGutters>
                <Col className="mr-2">
                  <Form.Group controlId="formLastName">
                    <Row>
                      <Col className="h6 text-left">Last name</Col>
                    </Row>
                    <Form.Control
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      className="btn-form-primary"
                    />
                    {touched.lastName && errors.lastName && <div className="errorMessage">{errors.lastName}</div>}
                  </Form.Group>
                </Col>
                <Col className="ml-2">
                  <Form.Group controlId="formFirstName">
                    <Row>
                      <Col className="h6 text-left">First name</Col>
                    </Row>
                    <Form.Control
                      type="text"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      className="btn-form-primary"
                    />
                    {touched.firstName && errors.firstName && <div className="errorMessage">{errors.firstName}</div>}
                  </Form.Group>
                </Col>
              </Row>

              <Row noGutters>
                <Col className="mr-2">
                  <Form.Group controlId="formHospital">
                    <Row>
                      <Col className="h6 text-left">Hospital</Col>
                    </Row>
                    <Dropdown>
                      <Dropdown.Toggle block variant="form-primary">
                        {values.hospitalId ? (
                          findHospitalName(values.hospitalId)
                        ) : (
                          <span style={{ color: '#6c757d' }}>Hospital</span>
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {hospitals.map((h) => (
                          <Dropdown.Item
                            key={h.id}
                            value={h.id}
                            onClick={() => {
                              setFieldValue('hospitalId', h.id);
                            }}
                          >
                            {h.name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    {touched.hospitalId && errors.hospitalId && <div className="errorMessage">{errors.hospitalId}</div>}
                  </Form.Group>
                </Col>

                <Col className="ml-2">
                  <Form.Group controlId="formFloor">
                    <Row>
                      <Col className="h6 text-left">Floor</Col>
                    </Row>
                    <Form.Control
                      type="text"
                      name="floor"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.floor}
                      className="btn-form-primary"
                    />
                    {touched.floor && errors.floor && <div className="errorMessage">{errors.floor}</div>}
                  </Form.Group>
                </Col>
              </Row>

              <Row noGutters>
                <Col className="mr-2">
                  <Form.Group controlId="formDoctor">
                    <Row>
                      <Col className="h6 text-left">Doctor</Col>
                    </Row>
                    <Dropdown>
                      <Dropdown.Toggle block variant="form-primary">
                        {values.doctorId ? (
                          findDoctorName(values.doctorId)
                        ) : (
                          <span style={{ color: '#6c757d' }}>Doctor</span>
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {doctors.map((d) => (
                          <Dropdown.Item
                            key={d.id}
                            value={d.id}
                            onClick={() => {
                              setFieldValue('doctorId', d.id);
                            }}
                          >
                            {d.lastName}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    {touched.doctorId && errors.doctorId && <div className="errorMessage">{errors.doctorId}</div>}
                  </Form.Group>
                </Col>

                <Col className="ml-2">
                  <Form.Group controlId="formDiagnosis">
                    <Row>
                      <Col className="h6 text-left">Diagnosis</Col>
                    </Row>
                    <Form.Control
                      type="text"
                      name="diagnosis"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.diagnosis}
                      className="btn-form-primary"
                    />
                    {touched.diagnosis && errors.diagnosis && <div className="errorMessage">{errors.diagnosis}</div>}
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col>
                <Button block variant="custom-primary" type="submit" spinner={isSubmitting}>
                  Save
                </Button>
              </Col>
              <Col>
                <Button block variant="custom-secondary" onClick={() => onClose()}>
                  Close
                </Button>
              </Col>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PatientModal;
