import { Nav, Navbar, Image, Col, Row } from 'react-bootstrap';
import { useAuth } from './AuthProvider';
import { Link, useLocation } from 'react-router-dom';
import logo from '../images/logo.svg';
import { Gear } from 'react-bootstrap-icons';
import { Button } from './ui/Button';
import { useIsMobile } from './MobileProvider';
import { showLandingNav } from './LandingNav';
import './topNav.css';

const TopNav: React.FC = () => {
  const auth = useAuth();
  const { isMobile } = useIsMobile();
  const location = useLocation();

  if (!showLandingNav(location.pathname)) {
    return (
      <Navbar bg="#fdfdfd" expand="lg">
        <Navbar.Brand as={Link} to={location.pathname !== '/login' ? '/notes' : '/'} className="mb-auto">
          <Image style={{ maxHeight: 32, maxWidth: '100%' }} src={logo} fluid />
        </Navbar.Brand>

        {auth.user && (
          <Nav className="ml-auto">
            <Row noGutters>
              <Col className={isMobile() ? 'mr-2' : ''}>
                <Nav.Link as={Link} to="/settings/referrals">
                  <Button>Earn $50</Button>
                </Nav.Link>
              </Col>

              <Col>
                <Nav.Link as={Link} to={`/settings${!isMobile() ? '/profile' : ''}`}>
                  <Gear size={20} />
                </Nav.Link>
              </Col>
            </Row>
          </Nav>
        )}
      </Navbar>
    );
  } else {
    return null;
  }
};

export default TopNav;
