import React, { useState } from 'react';
import { Container, Row, Col, Collapse, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from './ui/Button';
import DisplayError from './DisplayError';
import { FormTextInput } from './ui/FormTextInput';

type Props = {
  title: string;
  error?: string;
  setError: Function;
  modalButtonText?: string;
  setShowModal?: Function;
  collapseButtonText?: string;
  collapseSave?: Function;
};

const SettingsCard: React.FC<Props> = ({
  title,
  error,
  setError,
  modalButtonText,
  setShowModal,
  collapseButtonText,
  collapseSave,
  children,
}) => {
  const [openInput, setOpenInput] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Name must be at least two characters').required('Name is required'),
  });

  return (
    <div>
      <Row className="justify-content-left">
        <Col sm={12} md={11} lg={10}>
          <Container className="loginContainer p-4">
            <Row className="mb-2">
              <Col className="h3 text-left">{title}</Col>
              {modalButtonText && setShowModal ? (
                <Col className="my-auto text-right">
                  <Button onClick={() => setShowModal(true)}>{modalButtonText}</Button>
                </Col>
              ) : null}

              {collapseButtonText && collapseSave ? (
                <Col className="my-auto text-right">
                  <Button
                    onClick={() => setOpenInput(!openInput)}
                    aria-controls="collapse-input"
                    aria-expanded={openInput}
                  >
                    {collapseButtonText}
                  </Button>
                </Col>
              ) : null}
            </Row>

            <Row>
              <Col>{error && <DisplayError error={error} setError={setError}></DisplayError>}</Col>
            </Row>

            {collapseButtonText && collapseSave ? (
              <Collapse in={openInput}>
                <Row id="collapse-input" className="mb-3">
                  <Col>
                    <Formik
                      validationSchema={validationSchema}
                      initialValues={{ name: '' }}
                      onSubmit={async (values, { setSubmitting, resetForm }) => {
                        // When button submits form and form is in the process of submitting, submit button is disabled
                        setSubmitting(true);
                        await collapseSave(values.name, setSubmitting, resetForm);
                        setOpenInput(false);
                      }}
                    >
                      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                        <Form onSubmit={handleSubmit}>
                          <FormTextInput
                            name="name"
                            header="Name"
                            values={values}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                          />

                          <Row>
                            <Col>
                              <Button className="mx-1" type="submit" spinner={isSubmitting}>
                                Save
                              </Button>
                              <Button className="mx-1" variant="custom-secondary" onClick={() => setOpenInput(false)}>
                                Close
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </Collapse>
            ) : null}

            {children}
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default SettingsCard;
