import React from 'react';
import { ChevronLeft } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import './backButton.css'

type Props = {
    to: string;
};

const BackButton: React.FC<Props> = ({ to, children }) => {
    return (
        <Link to={to} className="backButton"><ChevronLeft /> {children}</Link>
    );
}

export default BackButton;