import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { Role } from './Roles';
import * as Yup from 'yup';
import './modal.css';
import { useAuth } from './AuthProvider';
import DisplayError from './DisplayError';
import { handleError } from '../helper/error';
import { Button } from './ui/Button';
import { FormTextInput } from './ui/FormTextInput';
import { FormDropdown } from './ui/FormDropdown';
import { User } from './Users';

type Props = {
  editUser: User;
  roles: Role[];
  showModal: boolean;
  setShowModal: Function;
  refreshTable: Function;
};

const EditUserModal: React.FC<Props> = ({ editUser, roles, showModal, setShowModal, refreshTable }) => {
  const [error, setError] = useState<string>();
  const { Put } = useAuth();

  // Schema for yup
  const validationSchema = Yup.object().shape({
    lastName: Yup.string()
      .min(2, 'Last name must have at least 2 characters')
      .max(50, 'Last name cannot be longer than 50 characters')
      .required('Last name is required'),
    firstName: Yup.string()
      .min(2, 'First name must have at least 2 characters')
      .max(50, 'First name cannot be longer than 50 characters')
      .required('First name is required'),
    roleId: Yup.string().required('Role is required'),
    isDoctor: Yup.boolean(),
  });

  return (
    <Modal show={showModal} onHide={() => setShowModal(undefined)} animation={false}>
      {error && <DisplayError error={error} setError={setError}></DisplayError>}
      <Formik
        initialValues={{
          lastName: editUser.lastName,
          firstName: editUser.firstName,
          roleId: editUser.roleId,
          isDoctor: editUser.isDoctor ? '1' : '0',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);

          const user: User = {
            id: editUser.id,
            groupId: editUser.groupId,
            lastName: values.lastName,
            firstName: values.firstName,
            roleId: values.roleId,
            email: editUser.email,
            isDoctor: values.isDoctor === '1', // convert this to bool for api
          };

          try {
            await Put(`/api/v1/users/${editUser.id}`, user);

            resetForm();
            setSubmitting(false);
            refreshTable();
            setShowModal(undefined);
          } catch (err) {
            handleError(err, setError);
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Invite User</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <FormTextInput
                name="firstName"
                header="First Name"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              />

              <FormTextInput
                name="lastName"
                header="Last Name"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              />

              <FormDropdown
                name="roleId"
                header="Role"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              >
                <option value={''}>Role</option>
                {roles.map((r) => (
                  <option key={r.id} value={r.id}>
                    {r.name}
                  </option>
                ))}
              </FormDropdown>

              <FormDropdown
                name="isDoctor"
                header="Is this user a doctor?"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              >
                <option value={0}>No</option>
                <option value={1}>Yes</option>
              </FormDropdown>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="custom-primary" type="submit" spinner={isSubmitting}>
                Save
              </Button>
              <Button variant="custom-secondary" onClick={() => setShowModal(undefined)}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditUserModal;
