import React, { createContext, useContext, useEffect, useState } from 'react';
import axios, { AxiosResponse, AxiosRequestConfig, ResponseType } from 'axios';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { Permission } from './Roles';

const TEN_MINUTES = 1000 * 60 * 10;

export type AuthUser = {
  id: string;
  groupId: string;
  groupStatus: 'Valid' | 'NeedsPayment';
  roleId: string;
  permissions: Permission[];
  isDoctor: boolean;
  lastName: string;
  firstName: string;
  email: string;
};

type AuthContextType = {
  user: AuthUser | null;
  login: Function;
  logout: Function;
  getLoggedInUser: () => AuthUser | null;
  tokenIsValid: Function;
  tokenExpiresIn: Function;
  Get: Function;
  Post: Function;
  Put: Function;
  Patch: Function;
  Delete: Function;
  hasPermission: (p: Permission) => boolean;
};

type JwtClaims = {
  id: string;
  groupId: string;
  groupStatus: 'Valid' | 'NeedsPayment';
  roleId: string;
  permissions: Permission[];
  isDoctor: boolean;
  email: string;
  firstName: string;
  lastName: string;
  exp: number;
};

const AuthContext = createContext<AuthContextType>({
  user: null,
  login: () => {},
  logout: () => {},
  getLoggedInUser: () => null,
  tokenIsValid: () => {},
  tokenExpiresIn: () => {},
  Get: () => {},
  Post: () => {},
  Put: () => {},
  Patch: () => {},
  Delete: () => {},
  hasPermission: (p: Permission) => false,
});

export const AuthProvider: React.FC = ({ children }) => {
  const auth = useProvideAuth();
  const history = useHistory();

  const handleOnIdle = () => {
    auth.logout();
    history.push('/');
  };

  const handleOnAction = () => {
    const expiresIn = auth.tokenExpiresIn();
    if (expiresIn > 0) {
      if (expiresIn < TEN_MINUTES) {
        try {
          auth.Get('/api/v1/token');
        } catch (e) {
          auth.logout();
          history.push('/');
        }
        reset();
      }
    } else {
      auth.logout();
      history.push('/');
    }
  };

  const { reset, pause } = useIdleTimer({
    timeout: 1000 * 60 * 15, // 15 min which matches JWT expiration period
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    throttle: 500, // onAction runs only every 500ms.
    crossTab: true, // reset idle timer from other tabs
  });

  // Only run the autologout timer when there is a user present.
  useEffect(() => {
    if (auth.user) {
      reset();
    } else {
      pause();
    }
  }, [auth.user, reset, pause]);

  // Force user back into context on page refresh if still token exists.
  if (!auth.user && auth.tokenIsValid()) {
    auth.setUser(auth.getLoggedInUser());
  }

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

const loadSavedClaims = (): JwtClaims | null => {
  const token = localStorage.getItem('token');
    if (!token) {
    return null;
  }
  const decodedJwt = jwt.decode(token, { complete: true });
  if (!decodedJwt) {
    return null;
  }
  return decodedJwt.payload as JwtClaims;
};

const useProvideAuth = () => {
  const [user, setUser] = useState<AuthUser | null>(null);

  const logout = () => {
    localStorage.removeItem('token');
    setUser(null);
  };

  const login = async (email: string, password: string) => {
    const res: AxiosResponse = await axios.post(`/api/login`, {
      email: email,
      password: password,
    });

    processToken(res);
  };

  const getLoggedInUser = (): AuthUser | null => {
    const claims = loadSavedClaims();
    if (!claims) {
      return null;
    }
    const loggedInUser: AuthUser = {
      id: claims.id,
      groupId: claims.groupId,
      groupStatus: claims.groupStatus,
      roleId: claims.roleId,
      permissions: claims.permissions,
      isDoctor: claims.isDoctor,
      email: claims.email,
      lastName: claims.lastName,
      firstName: claims.firstName,
    };

    return loggedInUser;
  };

  const tokenIsValid = (): boolean => {
    const claims = loadSavedClaims();
    if (!claims) {
      return false;
    }
    const now = Math.floor(Date.now() / 1000); // Backend uses seconds, not milliseconds.
    return claims.exp >= now;
  };

  // tokenExpiresIn returns the number of milliseconds until the token expires.
  // An invalid or expired token returns values less than 0.
  const tokenExpiresIn = (): number => {
    const claims = loadSavedClaims();
    if (!claims) {
      return -1;
    }
    const now = Math.floor(Date.now());
    return claims.exp * 1000 - now; // Backend uses seconds, not milliseconds.
  };

  const hasPermission = (permission: Permission): boolean => {
    if (user?.permissions?.find((p) => p === permission)) {
      return true;
    }
    return false;
  };

  const processToken = (res: AxiosResponse) => {
    const token = res.headers['x-token'];
    if (!token) return;

    const decodedJwt = jwt.decode(token, { complete: true });

    if (!decodedJwt) {
      throw new Error('failed to decode jwt');
    }

    const claims = decodedJwt.payload as JwtClaims;

    const loggedInUser: AuthUser = {
      id: claims.id,
      groupId: claims.groupId,
      groupStatus: claims.groupStatus,
      roleId: claims.roleId,
      permissions: claims.permissions,
      isDoctor: claims.isDoctor,
      email: claims.email,
      lastName: claims.lastName,
      firstName: claims.firstName,
    };

    setUser(loggedInUser);
    localStorage.setItem('token', token);
  };

  const Get = async (url: string, responseType?: ResponseType) => {
    const requestConfig: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    if (responseType) {
      requestConfig.responseType = responseType;
    }

    const res = await axios.get(url, requestConfig);
    processToken(res);
    return res;
  };

  const Post = async (url: string, body: any) => {
    const res = await axios.post(url, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    processToken(res);
    return res;
  };

  const Put = async (url: string, body: any) => {
    const res = await axios.put(url, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    processToken(res);
    return res;
  };

  const Patch = async (url: string, body: any) => {
    const res = await axios.patch(url, body, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    processToken(res);
    return res;
  };

  const Delete = async (url: string) => {
    const res = await axios.delete(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    processToken(res);
    return res;
  };

  return {
    user,
    setUser, // not ideal to include this, but it helps us deal with page refresh.
    login,
    logout,
    getLoggedInUser,
    tokenIsValid,
    tokenExpiresIn,
    hasPermission,
    Get,
    Post,
    Put,
    Patch,
    Delete,
  };
};
