import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Row, Col, ListGroup } from 'react-bootstrap';
import InviteUserModal from './InviteUserModal';
import { Role } from './Roles';
import { useAuth } from './AuthProvider';
import { handleError } from '../helper/error';
import { Spinner } from './ui/Spinner';
import EditUserModal from './EditUserModal';
import SettingsItem from './SettingsItem';
import SettingsCard from './SettingsCard';
import SuccessAlert from './SuccessAlert';

export type User = {
  id: string;
  groupId: string;
  roleId: string;
  isDoctor: boolean;
  lastName: string;
  firstName: string;
  email: string;
};

export type Invite = {
  id: string;
  groupId: string;
  roleId: string;
  isDoctor: boolean;
  email: string;
  used: Number | null;
  expiration: Number;
};

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [unusedInvites, setUnusedInvites] = useState<Invite[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [error, setError] = useState<string>();
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [currentEditModal, setCurrentEditModal] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [success, setSuccess] = useState<string>();
  const { Get, Delete } = useAuth();

  const refreshTable = useCallback(async () => {
    try {
      const promises: Promise<AxiosResponse>[] = [Get(`/api/v1/users`), Get(`/api/v1/invites`), Get(`/api/v1/roles`)];

      const [userResponse, invitesResponse, roleResponse] = await Promise.all(promises);
      setUsers(userResponse.data);
      setUnusedInvites(invitesResponse.data);
      setRoles(roleResponse.data);
      setIsLoading(false);
    } catch (err) {
      handleError(err, setError);
      setIsLoading(false);
    }
  }, [Get]);

  const deleteUser = async (id: string) => {
    try {
      await Delete(`/api/v1/users/${id}`);
      refreshTable();
    } catch (err) {
      handleError(err, setError);
    }
  };

  const deleteInvite = async (id: string) => {
    try {
      await Delete(`/api/v1/invites/${id}`);
      refreshTable();
    } catch (err) {
      handleError(err, setError);
    }
  };

  const getRoleName = (id: string) => {
    const role = roles.find((r) => r.id === id);
    return role?.name;
  };

  useEffect(() => {
    refreshTable();
  }, [refreshTable]);

  return (
    <SettingsCard
      title="Users"
      error={error}
      setError={setError}
      setShowModal={setShowInviteModal}
      modalButtonText="Invite User"
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SuccessAlert message={success} />

          <Row>
            <Col>
              <ListGroup>
                {users.map((u) => {
                  return (
                    <div key={u.id}>
                      <SettingsItem
                        key={u.id}
                        itemId={u.id}
                        heading={`${u.isDoctor ? 'Dr. ' : ''}${u.firstName} ${u.lastName}`}
                        subHeading={`${getRoleName(u.roleId)}, ${u.email}`}
                        deleteConfirmText="Are you sure you want to delete this user?"
                        deleteItem={deleteUser}
                        setCurrentEditModal={setCurrentEditModal}
                      />

                      {/* TODO: If we edit our ourself we should really get a new login token, etc. Our permissions may change */}
                      <EditUserModal
                        key={u.id + u.firstName}
                        editUser={u}
                        roles={roles}
                        showModal={u.id === currentEditModal}
                        setShowModal={setCurrentEditModal}
                        refreshTable={refreshTable}
                      />
                    </div>
                  );
                })}
                {unusedInvites.length > 0 && (
                  <>
                    <hr />
                    <h4 className="text-left">Pending Invitations</h4>
                    {unusedInvites.map((inv) => {
                      return (
                        <div key={inv.id + inv.email}>
                          <SettingsItem
                            key={inv.id}
                            itemId={inv.id}
                            heading={inv.email}
                            subHeading={getRoleName(inv.roleId)}
                            deleteConfirmText="Are you sure you want to delete this invite?"
                            deleteItem={deleteInvite}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
              </ListGroup>
            </Col>
          </Row>

          <InviteUserModal
            roles={roles}
            showModal={showInviteModal}
            setShowModal={setShowInviteModal}
            refreshTable={refreshTable}
            setSuccess={setSuccess}
          />
        </>
      )}
    </SettingsCard>
  );
};

export default Users;
