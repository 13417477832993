import React, { useState } from 'react';
import { Form, Modal, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import './modal.css';
import { useAuth } from './AuthProvider';
import DisplayError from './DisplayError';
import { handleError } from '../helper/error';
import { Button } from './ui/Button';
import { FormTextInput } from './ui/FormTextInput';
import { useIsMobile } from './MobileProvider';

export type ModalRole = {
  name: string;
  permissions: string[];
};

type Props = {
  permissions: string[];
  showModal: boolean;
  setShowModal: Function;
  refreshTable: Function;
};

const RoleModal: React.FC<Props> = ({ permissions, showModal, setShowModal, refreshTable }) => {
  const [error, setError] = useState<string>();
  const { Post } = useAuth();
  const { isMobile } = useIsMobile();

  // Schema for yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Name is required').required('Name is required'),
    permissions: Yup.array().min(1, 'At least one permission is required'),
  });

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)} animation={false} centered={isMobile()}>
      <Formik
        initialValues={{ name: '', permissions: [] }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // When button submits form and form is in the process of submitting, submit button is disabled
          setSubmitting(true);

          const role: ModalRole = {
            name: values.name,
            permissions: values.permissions,
          };

          try {
            await Post(`/api/v1/roles`, role);
            resetForm();
            setSubmitting(false);
            refreshTable();
            setShowModal(false);
          } catch (err) {
            setSubmitting(false);
            handleError(err, setError);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>New Role</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {error && <DisplayError error={error} setError={setError}></DisplayError>}

              <FormTextInput
                name="name"
                header="Name"
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched}
                errors={errors}
              />

              <Form.Group controlId="formBasicCheckbox">
                <Row>
                  <Col className="h6 text-left">Permissions</Col>
                </Row>

                {permissions.map((p) => {
                  return (
                    <Form.Check
                      key={p}
                      type="checkbox"
                      name="permissions"
                      label={p}
                      value={p}
                      onChange={handleChange}
                    />
                  );
                })}

                <Row>
                  <Col className="text-left">
                    {touched.permissions && errors.permissions && (
                      <div className="errorMessage">{errors.permissions}</div>
                    )}
                  </Col>
                </Row>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" spinner={isSubmitting}>
                Save
              </Button>
              <Button variant="custom-secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default RoleModal;
