import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { Row, Col, Card, Table, Collapse, Form, Alert, Popover, OverlayTrigger } from 'react-bootstrap';
import { useAuth } from './AuthProvider';
import { Formik } from 'formik';
import { handleError } from '../helper/error';
import { QuestionCircle } from 'react-bootstrap-icons';
import SettingsCard from './SettingsCard';
import { Button } from './ui/Button';
import { Spinner } from './ui/Spinner';
import { useIsMobile } from './MobileProvider';
import { CardElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import './billing.css';
import { loadStripe } from '@stripe/stripe-js';
import ReactGA from 'react-ga';

type BillingInvoice = {
  id: string;
  amount: number;
  created: number;
};

type BillingDetails = {
  nextPayment: number;
  created: number;
  periodStart: number;
  periodEnd: number;
  lastFour: string;
  invoices: BillingInvoice[];
  balance: number;
};

const Billing: React.FC = () => {
  const [billingDetails, setBillingDetails] = useState<BillingDetails>();
  const [error, setError] = useState<string>();
  const [openUpdatePayment, setOpenUpdatePayment] = useState<boolean>(false);
  const { isMobile } = useIsMobile();
  const { Get, Post, getLoggedInUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Google analytics tracking.
  ReactGA.pageview(window.location.pathname + window.location.search, undefined, 'Billing');

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

  const refreshTable = useCallback(async () => {
    try {
      const res: AxiosResponse<BillingDetails> = await Get(`/api/v1/billing`);
      setBillingDetails(res.data);
      setIsLoading(false);
    } catch (err) {
      handleError(err, setError);
      setIsLoading(false);
    }
  }, [Get]);

  const formatDate = (d: Date) => {
    return d.toLocaleString('en-US', {
      year: 'numeric',
      month: isMobile() ? 'numeric' : 'long',
      day: 'numeric',
    });
  };

  useEffect(() => {
    refreshTable();
  }, [refreshTable]);

  const referralPopover = (
    <Popover id="popover-basic">
      <Popover.Content>
        This is the amount that will be deducted from your next payment(s) because of referrals your organization has
        made.
      </Popover.Content>
    </Popover>
  );

  return (
    <Elements stripe={stripePromise}>
      <SettingsCard title="Billing" error={error} setError={setError}>
        {isLoading ? (
          <Spinner />
        ) : (
          <div>
            {/* If group status is invalid, force payment info update. */}
            {getLoggedInUser()?.groupStatus === 'NeedsPayment' && billingDetails ? (
              <Alert variant="danger">
                <Row className="justify-content-center errorRow">
                  <Col className="text-left mb-3 mt-2">
                    {billingDetails?.lastFour
                      ? `Unfortunately, your most recent payment of $${billingDetails.nextPayment / 100} could not be
                      processed. Your account is currently in a suspended state and cannot be used until a payment is
                      made. Please update your payment information to make a payment and re-enable your account. We're
                      sorry for any inconvenience.`
                      : `Unfortunately, your trial period has ended. We hope you have enjoyed Roundnotes! To continue using the 
                      application, please add payment information below and pay the invoice for the amount of 
                      $${billingDetails.nextPayment / 100}.`}
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <ElementsConsumer>
                      {({ elements, stripe }) => (
                        <Formik
                          initialValues={{}}
                          onSubmit={async (values, { setSubmitting, resetForm }) => {
                            // When button submits form and form is in the process of submitting, submit button is disabled
                            setSubmitting(true);

                            try {
                              if (!stripe || !elements) {
                                // Stripe.js has not loaded yet. Make sure to disable
                                // form submission until Stripe.js has loaded.
                                return;
                              }

                              // Get a reference to a mounted CardElement. Elements knows how
                              // to find your CardElement because there can only ever be one of
                              // each type of element.
                              const cardElement = elements.getElement(CardElement);

                              if (!cardElement) {
                                console.log('[error] no card element');
                                return;
                              }

                              const { error: cardError, paymentMethod } = await stripe.createPaymentMethod({
                                type: 'card',
                                card: cardElement,
                              });

                              if (cardError) {
                                throw cardError;
                              } else {
                                console.log('[PaymentMethod]', paymentMethod);
                              }

                              // If we had a card on file, update payment method. (Payment failed).
                              if (billingDetails?.lastFour) {
                                await Post('/api/v1/billing/update-payment', {
                                  paymentMethodId: paymentMethod?.id,
                                });

                                ReactGA.event({
                                  category: 'Billing',
                                  action: 'Update payment info after failure',
                                });
                              } else {
                                // Otherwise, add the payment method. (Trial ended).
                                await Post('/api/v1/billing/add-payment', {
                                  paymentMethodId: paymentMethod?.id,
                                });

                                ReactGA.event({
                                  category: 'Billing',
                                  action: 'Add payment info at trial end',
                                });
                              }

                              // Pay the invoice using the updated payment info.
                              await Post('/api/v1/billing/pay-recent-invoice', {
                                paymentMethodId: paymentMethod?.id,
                              });

                              // setSuccess(true);
                              setSubmitting(false);
                              resetForm();
                            } catch (err) {
                              handleError(err, setError);
                              setSubmitting(false);
                            }
                          }}
                        >
                          {({ handleSubmit, isSubmitting }) => (
                            <Form onSubmit={handleSubmit}>
                              <Row className="justify-content-md-center">
                                <Col sm={12} md={12} lg={12}>
                                  <Form.Group controlId="formCreditCardInfo">
                                    <Row>
                                      <Col className="h6 text-left">Credit card</Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <CardElement
                                          options={{
                                            style: {
                                              base: {
                                                fontSmoothing: 'antialiased',
                                                fontFamily: 'sans-serif',
                                                fontSize: '14px',
                                                fontWeight: 1,
                                                color: '#495057',
                                                '::placeholder': {
                                                  color: '#aab7c4',
                                                },
                                              },
                                              invalid: {
                                                color: '#9e2146',
                                              },
                                            },
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  </Form.Group>
                                </Col>
                              </Row>

                              <Row className="mb-2">
                                <Col>
                                  <Button className="mx-1" type="submit" spinner={isSubmitting}>
                                    {billingDetails?.lastFour
                                      ? 'Update payment information and pay invoice'
                                      : 'Add payment information and pay invoice'}
                                  </Button>
                                </Col>
                              </Row>
                            </Form>
                          )}
                        </Formik>
                      )}
                    </ElementsConsumer>
                  </Col>
                </Row>
              </Alert>
            ) : null}

            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col className="text-left subtle">Next payment</Col>
                    </Row>

                    <Row>
                      <Col className="text-left heavyInfo">
                        ${billingDetails ? `${billingDetails.nextPayment / 100}.00` : null}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col className="text-left subtle">Payment date</Col>
                    </Row>

                    <Row>
                      <Col className="text-left heavyInfo">
                        {billingDetails ? formatDate(new Date(billingDetails.periodEnd * 1000)) : null}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {billingDetails && billingDetails.balance !== 0 ? (
              <Row className={isMobile() ? 'mt-1' : 'mt-3'}>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col className="text-left subtle">
                          <span>Referrer Bonus</span>{' '}
                          <span>
                            <OverlayTrigger placement="right" overlay={referralPopover}>
                              <QuestionCircle />
                            </OverlayTrigger>
                          </span>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="text-left heavyInfo">
                          ${billingDetails ? `${(-1 * billingDetails.balance) / 100}.00` : null}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            ) : null}

            {/* If group status is invalid, do not allow updating payment info */}
            {getLoggedInUser()?.groupStatus !== 'NeedsPayment' ? (
              <div>
                {/* If lastFour (of credit card) exists, then we have payment info and user can update it. */}
                {billingDetails?.lastFour ? (
                  <div className="mt-4 mb-3">
                    <Row className="mt-1">
                      <Col className="text-left">
                        <Button block={isMobile()} onClick={() => setOpenUpdatePayment(!openUpdatePayment)}>
                          Update payment information
                        </Button>
                      </Col>
                    </Row>

                    <Collapse in={openUpdatePayment}>
                      <Row id="collapse-input" className="mt-4">
                        <Col>
                          <ElementsConsumer>
                            {({ elements, stripe }) => (
                              <Formik
                                initialValues={{}}
                                onSubmit={async (values, { setSubmitting, resetForm }) => {
                                  // When button submits form and form is in the process of submitting, submit button is disabled
                                  setSubmitting(true);

                                  try {
                                    if (!stripe || !elements) {
                                      // Stripe.js has not loaded yet. Make sure to disable
                                      // form submission until Stripe.js has loaded.
                                      return;
                                    }

                                    // Get a reference to a mounted CardElement. Elements knows how
                                    // to find your CardElement because there can only ever be one of
                                    // each type of element.
                                    const cardElement = elements.getElement(CardElement);

                                    if (!cardElement) {
                                      console.log('[error] no card element');
                                      return;
                                    }

                                    const { error: cardError, paymentMethod } = await stripe.createPaymentMethod({
                                      type: 'card',
                                      card: cardElement,
                                    });

                                    if (cardError) {
                                      throw cardError;
                                    } else {
                                      console.log('[PaymentMethod]', paymentMethod);
                                    }

                                    await Post('/api/v1/billing/update-payment', {
                                      paymentMethodId: paymentMethod?.id,
                                    });

                                    // setSuccess(true);
                                    setSubmitting(false);
                                    resetForm();
                                    setOpenUpdatePayment(false);
                                  } catch (err) {
                                    handleError(err, setError);
                                    setSubmitting(false);
                                  }
                                }}
                              >
                                {({ handleSubmit, isSubmitting }) => (
                                  <Form onSubmit={handleSubmit}>
                                    <Row className="justify-content-md-center">
                                      <Col sm={12} md={12} lg={12}>
                                        <Form.Group controlId="formCreditCardInfo">
                                          <Row>
                                            <Col className="h6 text-left">Credit card</Col>
                                          </Row>
                                          <Row>
                                            <Col>
                                              <CardElement
                                                options={{
                                                  style: {
                                                    base: {
                                                      fontSmoothing: 'antialiased',
                                                      fontFamily: 'sans-serif',
                                                      fontSize: '14px',
                                                      fontWeight: 1,
                                                      color: '#495057',
                                                      '::placeholder': {
                                                        color: '#aab7c4',
                                                      },
                                                    },
                                                    invalid: {
                                                      color: '#9e2146',
                                                    },
                                                  },
                                                }}
                                              />
                                            </Col>
                                          </Row>
                                        </Form.Group>
                                      </Col>
                                    </Row>

                                    <Row>
                                      <Col>
                                        <Button className="mx-1" type="submit" spinner={isSubmitting}>
                                          Save
                                        </Button>
                                        <Button
                                          className="mx-1"
                                          variant="custom-secondary"
                                          onClick={() => setOpenUpdatePayment(false)}
                                        >
                                          Close
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Form>
                                )}
                              </Formik>
                            )}
                          </ElementsConsumer>
                        </Col>
                      </Row>
                    </Collapse>
                  </div>
                ) : (
                  // Allow adding payment info because we have none and group is still valid (in trial period).
                  <Row className="mt-3">
                    <Col>
                      <Alert variant="warning">
                        <Row className="mt-2">
                          <Col className="text-left">
                            You do not currently have any payment information on file. To avoid any service
                            interruptions, you can add your payment information below.
                          </Col>
                        </Row>

                        <Row className="text-center mt-3 mb-2">
                          <Col>
                            <ElementsConsumer>
                              {({ elements, stripe }) => (
                                <Formik
                                  initialValues={{}}
                                  onSubmit={async (values, { setSubmitting, resetForm }) => {
                                    // When button submits form and form is in the process of submitting, submit button is disabled
                                    setSubmitting(true);

                                    try {
                                      if (!stripe || !elements) {
                                        // Stripe.js has not loaded yet. Make sure to disable
                                        // form submission until Stripe.js has loaded.
                                        return;
                                      }

                                      // Get a reference to a mounted CardElement. Elements knows how
                                      // to find your CardElement because there can only ever be one of
                                      // each type of element.
                                      const cardElement = elements.getElement(CardElement);

                                      if (!cardElement) {
                                        console.log('[error] no card element');
                                        return;
                                      }

                                      const { error: cardError, paymentMethod } = await stripe.createPaymentMethod({
                                        type: 'card',
                                        card: cardElement,
                                      });

                                      if (cardError) {
                                        throw cardError;
                                      } else {
                                        console.log('[PaymentMethod]', paymentMethod);
                                      }

                                      await Post('/api/v1/billing/add-payment', {
                                        paymentMethodId: paymentMethod?.id,
                                      });

                                      ReactGA.event({
                                        category: 'Billing',
                                        action: 'Add payment info before trial end',
                                      });

                                      // setSuccess(true);
                                      setSubmitting(false);
                                      resetForm();
                                      setIsLoading(true);
                                      refreshTable();
                                    } catch (err) {
                                      handleError(err, setError);
                                      setSubmitting(false);
                                    }
                                  }}
                                >
                                  {({ handleSubmit, isSubmitting }) => (
                                    <Form onSubmit={handleSubmit}>
                                      <Row className="justify-content-md-center">
                                        <Col sm={12} md={12} lg={12}>
                                          <Form.Group controlId="formCreditCardInfo">
                                            <Row>
                                              <Col className="h6 text-left">Credit card</Col>
                                            </Row>
                                            <Row>
                                              <Col>
                                                <CardElement
                                                  options={{
                                                    style: {
                                                      base: {
                                                        fontSmoothing: 'antialiased',
                                                        fontFamily: 'sans-serif',
                                                        fontSize: '14px',
                                                        fontWeight: 1,
                                                        color: '#495057',
                                                        '::placeholder': {
                                                          color: '#aab7c4',
                                                        },
                                                      },
                                                      invalid: {
                                                        color: '#9e2146',
                                                      },
                                                    },
                                                  }}
                                                />
                                              </Col>
                                            </Row>
                                          </Form.Group>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col>
                                          <Button className="mx-1" type="submit" spinner={isSubmitting}>
                                            Save
                                          </Button>
                                        </Col>
                                      </Row>
                                    </Form>
                                  )}
                                </Formik>
                              )}
                            </ElementsConsumer>
                          </Col>
                        </Row>
                      </Alert>
                    </Col>
                  </Row>
                )}
              </div>
            ) : null}

            <Row>
              <Col className="text-left h5 mt-2">Payment History</Col>
            </Row>

            <Row>
              <Col>
                <Table className="mt-1">
                  <thead>
                    <tr>
                      <td className="text-left tableHeader">Date</td>
                      <td className="text-left tableHeader">Amount</td>
                    </tr>
                  </thead>
                  <tbody>
                    {billingDetails?.invoices?.map((i) => {
                      return (
                        <tr key={i.id}>
                          <td className="text-left">{formatDate(new Date(i.created * 1000))}</td>
                          <td className="text-left">${`${i.amount / 100}.00`}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>
        )}
      </SettingsCard>
    </Elements>
  );
};

export default Billing;
