import React, { useState } from 'react';
import { Row, Col, Alert, Modal, Container } from 'react-bootstrap';
import { useAuth } from './AuthProvider';
import SettingsCard from './SettingsCard';
import { handleError } from '../helper/error';
import { useIsMobile } from './MobileProvider';
import { Button } from './ui/Button';
import ReactGA from 'react-ga';

const Account: React.FC = () => {
  const [error, setError] = useState<string>();
  const { isMobile } = useIsMobile();
  const [showDeactivateModal, setShowDeactivateModal] = useState<boolean>(false);
  const [successfulDeactivation, setSuccessfulDeactivation] = useState<boolean>(false);
  const { Get } = useAuth();

  // Google analytics tracking.
  ReactGA.pageview(window.location.pathname + window.location.search, undefined, 'Account');

  const deactivateAccount = async () => {
    try {
      await Get(`/api/v1/billing/deactivate`);
      setSuccessfulDeactivation(true);
    } catch (err) {
      handleError(err, setError);
    }
  };

  const downloadBAA = async () => {
    try {
      const pdf = await Get(`/api/v1/baa`, 'arraybuffer');

      let blob = new Blob([pdf.data], { type: 'application/pdf' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'baa.pdf';
      link.click();
    } catch (err) {
      handleError(err, setError);
    }
  };

  return (
    <SettingsCard title="Account" error={error} setError={setError}>
      {successfulDeactivation ? (
        <Row>
          <Col>
            <Alert variant="warning" className="text-left">
              Your account has been deactivated. After logging out, you and any other providers associated with this
              account will no longer be able to log in. If you believe this message is an error, please contact us at
              business@roundnotes.com
            </Alert>
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col className="text-left h5 mt-3">Business Associate Agreement (BAA)</Col>
      </Row>

      <hr className="mt-1 mb-2" />

      <div className="mb-3">
        <Row className="mb-3">
          <Col className="text-left">
            The business associate agreement specifies responsibilities with respect to protected health information.
            The agreement allows your organization and Roundnotes to maintain HIPAA compliance. An administrator of your
            account signed this business associate agreement when signing up.
          </Col>
        </Row>

        <Row>
          <Col className="text-left">
            <Button block onClick={() => downloadBAA()}>
              Download BAA
            </Button>
          </Col>
        </Row>
      </div>

      <Row>
        <Col className="text-left h5 mt-3">Deactivate Account</Col>
      </Row>

      <hr className="mt-1 mb-2" />

      <div className="mb-3">
        <Row>
          <Col>
            <Alert variant="danger" className="text-left">
              Deactivating your account is a permanent action that will disable usage of Roundnotes for all users
              associated with this account. Your payment information will be disconnected. You and all other users
              associated with this account will no longer be able to log in and see or access any notes.
            </Alert>
          </Col>
        </Row>

        <Row>
          <Col className="text-left">
            <Button block onClick={() => setShowDeactivateModal(true)}>
              Deactivate account
            </Button>
          </Col>
        </Row>
      </div>

      <Modal
        show={showDeactivateModal}
        onHide={() => setShowDeactivateModal(false)}
        animation={false}
        centered={isMobile()}
      >
        <Modal.Body>
          <Container className="text-center">
            <Row>
              <Col className="my-1">
                Are you sure you want to deactivate your account? This action cannot be undone.
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  className="my-2 mx-1"
                  onClick={() => {
                    deactivateAccount();
                    ReactGA.event({
                      category: 'User',
                      action: 'Deactivate account',
                    });
                    setShowDeactivateModal(false);
                  }}
                >
                  Deactivate account
                </Button>

                <Button className="my-2 mx-1" variant="custom-secondary" onClick={() => setShowDeactivateModal(false)}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </SettingsCard>
  );
};

export default Account;
